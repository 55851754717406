import { AnyObject, HasId } from "../types/base.types";

export const AlwaysTrueFn = () => true;
export const AlwaysFalseFn = () => false;

export const always = <T>(a: T) => () => a;

export const cond = (
  items: [(...args: any) => any, (...args: any) => any][]
) => (...args: any) => {
  for (let i of items) {
    if (i[0](...args)) return i[1](...args);
  }
}

export const eqBy = <X, Y>(
  fn: (v: X | Y) => any = v => v, a: X, b: Y
) => fn(a) === fn(b);

export const eqByFn = (fn: (v: any) => any) => (a: any, b: any) => eqBy(fn, a, b);

export const first = <T>(arr?: T[]) => arr && arr.length > 0 ? arr[0] : undefined;

export const last = <T>(arr?: T[]) => arr ? arr[arr.length - 1] : undefined;
export const lastInString = (arr?: string) => arr ? arr[arr.length - 1] : undefined;

export const take = <T>(num: number, arr: T[]) => arr.slice(0, num);

export const sum = (numbers: number[]) => numbers.reduce((a,b) => a + b, 0);
export const mean = (numbers: number[]) => sum(numbers) / numbers.length;

export const median = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export function isNil(v: any): v is null | undefined {
  return v === null || v === undefined;
}
export const dissoc = <T extends AnyObject>(key: keyof T, obj: T): Omit<T, typeof key> => {
  const newObj: AnyObject = {};
  for (let k in obj) {
    if (k !== key) newObj[k] = obj[k];
  }
  return newObj as Omit<T, typeof key>;
}

export const omit = <T>(arr: T[], ...elementsToOmit: T[]) => {
  return arr.filter(a => !elementsToOmit.includes(a));
}

export const both = (a: Function, b: Function) => a() && b();
export const bothFn = (a: Function, b: Function) => () => a() && b();

export const prop = <T extends AnyObject>(key: keyof T, object: T) => object[key];
export const propFn = <T extends AnyObject>(key: keyof T) => (object: T) => prop(key, object);

export const groupBy = <T extends AnyObject>(fn: (obj: T) => any, array: T[]) => {
  const result = {} as AnyObject;
  array.forEach(o => {
    const key = fn(o) + '';
    if (!result[key]) result[key] = [];
    result[key].push(o);
  })
  return result as Record<string, T[]>;
}

export const allPass = (...fns: Function[]) => fns.every(f => f());
export const allPassFn = (...fns: Function[]) => () => fns.every(f => f());

export const uniq = <T >(arr: T[]) => Array.from(new Set(arr));
export const uniqById = <T extends HasId>(arr: T[]) => Array.from(new Set(arr.map(a => a.id))).map(id => arr.find(a => a.id === id)!);

export const findLast = <T >(fn: (x: T, i: number, a: T[]) => any, array: T[]) => [...array].reverse().find(fn);

export const pick = <T extends AnyObject>(keys: (keyof T)[], object: T) => {
  const result: AnyObject = {};
  const keysConst = [...keys] as const;
  keysConst.forEach(k => result[k as string] = object[k]);
  return result as Pick<T, typeof keysConst[number]>;
}

export const merge = <
  A extends AnyObject,
  B extends AnyObject,
  C extends AnyObject,
>(a?: A, b?: B, c?: C) => {
  return { ...a, ...b, ...c };
}
export const mergeLeft = <A extends AnyObject, B extends AnyObject>(a: A, b: B) => {
  return { ...b, ...a };
}
export const mergeRight = merge;

export const mapObject = <T extends AnyObject, K extends string, R>(
  transformer: (a: T) => R,
  object: T,
) => {
  const result = {} as AnyObject;
  Object.entries(object).forEach(e => result[e[0]] = transformer(e[1]));
  return result as Record<K, R>;
}


export const intersection = <TA, TB>(A: TA[], B: TB[]) => A.filter(a => B.includes(a as any));
export const difference = <TA, TB>(A: TA[], B: TB[]) => A.filter(a => !B.includes(a as any));
export const symmetricDifference = <TA, TB>(A: TA[], B: TB[]) => [...difference(A, B), ...difference(B, A)];

export const defaultComparator = (a: any, b: any) => a === b;
export const idComparator = (a: HasId, b: HasId) => a.id === b.id;

export const intersectionBy = <TA, TB>(comparator: (a: any, b: any) => boolean = defaultComparator, A: TA[], B: TB[]) => A.filter(a => B.find(b => comparator(a, b)));
export const differenceBy = <TA, TB>(comparator: (a: any, b: any) => boolean = defaultComparator, A: TA[], B: TB[]) => A.filter(a => !B.find(b => comparator(a, b)));
export const symmetricDifferenceBy = <TA, TB>(comparator: (a: any, b: any) => boolean = defaultComparator, A: TA[], B: TB[]) => [...differenceBy(comparator, A, B), ...differenceBy(comparator, B, A)];

