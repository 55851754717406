import sanitizeHtml, { AllowedAttribute } from 'sanitize-html';
import { AnyObject } from '../types/base.types';

export const sanitize = (content?: string | null | number | Boolean) => {
  return sanitizeHtml(content + '');
}

export const sanitizeAllowExtra = (
  content: string | null | number | Boolean,
  extraTags: string[],
  extraConfig?: {
    allowedTags?: string[] | boolean | AnyObject,
    allowedAttributes?: Record<string, AllowedAttribute[]> | false,
    allowedClasses?: { [index: string]: boolean | Array<string | RegExp> }
  }
) => {
  const config = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([...extraTags]),
    ...extraConfig
  } as sanitizeHtml.IOptions;
  return sanitizeHtml(content + '', config);
}