import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

type ScreenGuildIrelandLogoProps = {
  size?: string,
  width?: string,
  height?: string,
}

const ScreenGuildIrelandLogo: React.FC<ScreenGuildIrelandLogoProps> = props => {

  const { SGILogo } = useStaticQuery(graphql`
  {
    SGILogo: file(absolutePath: {regex: "/images/logo/"}, name: {eq: "logo-sgi"}) {
        id
        name
        ext
        childImageSharp {
          id
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  const p = props;

  return <GatsbyImage
    className="AppLogo SGILogo"
    imgClassName="AppLogoImage SGILogoImage"
    image={SGILogo.childImageSharp.gatsbyImageData}
    style={{
      width: p.width ?? p.size,
      height: p.height ?? p.size,
    }}
    alt={SGILogo.name}
    objectFit="contain"
  />
}

export default ScreenGuildIrelandLogo;