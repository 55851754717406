import './ToastCard.scss';

// import { IonItem, IonItemOption, IonItemOptions, IonItemSliding } from '@ionic/react';
import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import Hammer from 'react-hammerjs';

import { Nullable } from '../../types/base.types';
import { IToast } from '../../types/ui/ui.controllers.types';
import { Undefinable } from '../../types/utilities.types';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseIcon from '../BaseIcon/BaseIcon';
import BaseSpacer from '../BaseSpacer/BaseSpacer';

interface ToastProps {
  toast: IToast,
  canSwipeUpToDismiss?: boolean,
  onDismiss?: (t?: IToast) => void,
}

const ToastCard: React.FC<ToastProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get toast() {
      return p.toast;
    },
    get config() {
      return p.toast.config;
    },
    get icon() {
      return s.toast.config.icon;
    },
    get iconVariant() {
      return s.toast.config.iconVariant;
    },
    get iconSize() {
      return s.toast.config.iconSize;
    },
    get heading() {
      return s.toast.config.heading;
    },
    get body() {
      return s.toast.config.body;
    },
    get actions() {
      return s.toast.config.actions;
    },

    el: null as Nullable<HTMLDivElement>,
    setEl: action((el: HTMLDivElement) => {
      el && (s.el = el);
    }),
    getEl(): Undefinable<HTMLDivElement> {
      return (s.el || document.querySelector(`.ToastLayer[id="${s.toast.id}"] .ToastCard`)) as HTMLDivElement;
    },
    panning: false,
    handlePan: action((e: HammerInput) => {
      if (s.toast.status === 'opening') return;
      if (!p.canSwipeUpToDismiss) return;
      s.panning = true;
      const el = s.getEl();
      if (!el) return;
      el.style.transform = `translateY(${e.deltaY}px)`;
    }),
    handlePanEnd: () => s.clearTransform(),
    handlePanCancel: () => s.clearTransform(),
    clearTransform: flow(function* () {
      s.panning = false;
      if (!p.canSwipeUpToDismiss) return;
      const el = s.getEl();
      if (!el) return;
      el.style.transition = '.19s';
      el.style.removeProperty('transform');
      yield tick(190);
      el.style.removeProperty('transition');
    }),
    get backgroundColor() {
      return s.toast.config.color ?? 'hsl(var(--app-c-background-hsl))';
    },
    get deepInner() {
      return <div className="ToastDeepInner" style={
        s.shouldUseGestures ? {} : {
          backgroundColor: s.backgroundColor
        }
      } onClick={s.shouldUseGestures ? undefined : performAction()}>
        <div className="ToastContent">
          {/* {s.toast.config.timeCreated && <TimeDisplayer value={s.toast.config.timeCreated} />} */}
          {s.heading && <div className="ToastHeading">
            {s.icon && <BaseIcon icon={s.icon} variant={s.iconVariant} size={s.iconSize} />}
            <BaseSpacer inline size='xs' />
            {renderRenderable(s.heading)}
          </div>}
          {s.body && <div className="ToastBody">{renderRenderable(s.body)}</div>}
        </div>
        {
          s.shouldUseGestures || (
            <div className="ToastControls">
              {s.actions
                ? s.actions.map((action, idx) => (
                  <BaseButton key={idx} onClick={performAction(action.action)} stopPropagation>
                    {action.label}
                  </BaseButton>
                ))
                : <BaseButton
                  icon="close"
                  iconVariant="filled"
                  // appearance="tab"
                  // color="danger"
                  onClick={dismiss}
                  stopPropagation
                >
                  {s.config.dismissButtonLabel}
                </BaseButton>
              }
            </div>
          )
        }
      </div>
    },

    get shouldUseGestures() {
      // return UI.shouldUseGestures;
      return false;
    }

  }));

  const onMouseEnter = () => {
    s.toast.clearTimer();
  }
  const onMouseLeave = () => {
    s.toast.setTimer();
  }

  const handleSwipe = () => {
    if (p.canSwipeUpToDismiss) dismiss();
  }
  const dismiss = async (e?: React.MouseEvent) => {
    e?.stopPropagation();
    s.config.onBeforeDismiss && await s.config.onBeforeDismiss();
    s.toast.close();
    p.onDismiss && p.onDismiss(p.toast);
  }
  const performAction = (action?: (...arg: any) => void) => () => {
    // s.config.actions && s.config.actions();
    // s.config.actionUrl && NAVIGATOR.navigateTo(s.config.actionUrl);
    action?.();
    s.toast.close();
  }

  return <Observer children={() => {
    return <Hammer
      onPan={s.handlePan}
      onPanEnd={s.handlePanEnd}
      onPanCancel={s.handlePanCancel}
      onSwipe={p.canSwipeUpToDismiss ? handleSwipe : undefined}
      direction={p.canSwipeUpToDismiss ? "DIRECTION_UP" : undefined}
    >
      <section
        className={joinClassName(
          "ToastLayer",
          s.toast.status,
          // s.toast.config.colorCodedState && `state-${s.toast.config.colorCodedState}`,
          s.toast.config.color,
          s.shouldUseGestures && 'shouldUseGestures',
          !s.shouldUseGestures && 'noGestures',
        )}
        id={s.toast.id}
        data-name={s.toast.config.name}
        data-has-color={Boolean(s.toast.config.color)}
        data-cy={s.toast.config.dataCy}
      >
        <div className="ToastCard"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className="ToastInner">
            {s.shouldUseGestures ? (
              // To use this again, do:
              // npm i @ionic/react
              // <IonItemSliding>
              //   <IonItemOptions side="start" onIonSwipe={() => console.log('view swiped')}>
              //     <IonItemOption color="danger" onClick={dismiss}>{s.config.dismissButtonLabel || 'Dismiss'}</IonItemOption>
              //   </IonItemOptions>
              //   <IonItem style={{
              //     '--ion-background-color': s.backgroundColor,
              //     '--ion-text-color': 'hsl(var(--app-c-foreground-hsl))',
              //   }}>
              //     {s.deepInner}
              //   </IonItem>
              //   <IonItemOptions side="end" onIonSwipe={() => console.log('dismiss swiped')}>
              //     <IonItemOption color="danger" onClick={dismiss}>{s.config.dismissButtonLabel || 'Dismiss'}</IonItemOption>
              //   </IonItemOptions>
              // </IonItemSliding>
              s.deepInner
            ) : (
              s.deepInner
            )}
          </div>
        </div>
      </section>
    </Hammer>
  }} />
}

export default ToastCard;