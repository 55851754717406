import { action, observable } from "mobx";
import { AppController } from "./app.controller";
import store from 'store';

export const makeStoreController = () => {
  let initHasCalled = false;
  const c = observable({
    ready: false,
    namespace: 'mi',
    mode: 'local',
    root: null as unknown as AppController,
    init: action((root: AppController) => {
      if (initHasCalled) {
        console.warn('initiating the same STORE Controller instance twice. aborting.');
        return;
      }
      c.root = root;
      c.ready = true;
    }),

    // api.store.js.
    namespaceKey: (key: string) => {
      return `${c.namespace}::${key}`;
    },
    get: (key: string) => {
      return store.get(c.namespaceKey(key));
    },
    set: (key: string, value: string) => {
      return store.set(c.namespaceKey(key), value);
    },
    remove: (key: string) => {
      return store.remove(c.namespaceKey(key));
    },
    get clearAll() { return store.clearAll },
    get each() { return store.each },
  });

  return c;

}

export const STORE = makeStoreController();

export type StoreController = ReturnType<typeof makeStoreController>;