// if (process.env.NODE_ENV === 'development') {
//   process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'
// }
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// import 'swiper/css/swiper.min.css';

// import loadable from "@loadable/component";
// import "./src/style/index.scss";
import { Observer } from "mobx-react-lite";
import React from "react";
import initReactFastClick from "react-fastclick";

import configureMobx from "./src/@configureMobx";
import applyPolyfills from "./src/@polyfills";
import { AppContext, AppController } from "./src/controllers/app.controller";
import { detectBrowsers } from "./src/utils/browsers.utils";
import DialogStack from "./src/components/Dialog/DialogStack";
import OverlayStack from "./src/components/Overlay/OverlayStack";
import Symbols from "./src/components/Symbols/Symbols";
import ToastStack from "./src/components/Toast/ToastStack";
import WishlistScreen from "./src/components/WithQuery/WishlistScreen/WishlistScreen";
import AnalyticsManager from "./src/components/AnalyticsManager/AnalyticsManager";

// const DialogStack = loadable(() =>
//   import("./src/components/Dialog/DialogStack")
// );
// const OverlayStack = loadable(() =>
//   import("./src/components/Overlay/OverlayStack")
// );
// const Symbols = loadable(() => import("./src/components/Symbols/Symbols"));
// const ToastStack = loadable(() => import("./src/components/Toast/ToastStack"));
// const WishlistScreen = loadable(() =>
//   import("./src/components/WithQuery/WishlistScreen/WishlistScreen")
// );
// const AnalyticsManager = loadable(() =>
//   import("./src/components/AnalyticsManager/AnalyticsManager")
// );

applyPolyfills();
configureMobx();
detectBrowsers();
initReactFastClick();

// if (isBrowser && process.env.NODE_ENV === 'production') Sentry.init({
//   dsn: "https://5ebe11845822447f9c09b860004a8f03@o165875.ingest.sentry.io/5743081",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

export const wrapPageElement = (args) => {
  const { props, element } = args;
  return (
    <Observer
      children={() => (
        <AppContext.Provider value={AppController} {...props}>
          <Symbols />
          {element}
          <AnalyticsManager />
          <DialogStack />
          <OverlayStack />
          <ToastStack />
          <WishlistScreen />
        </AppContext.Provider>
      )}
    />
  );
};

export {};
