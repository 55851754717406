import { Observer } from 'mobx-react-lite';
import React from 'react';

import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { IconName, IconVariant } from '../Symbols';
import './BaseButton.scss';

export type BaseButtonEventType = React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>;

type BaseButtonProps = {
  className?: string,
  icon?: IconName;
  iconVariant?: IconVariant,
  iconPosition?: 'left' | 'right',
  iconSize?: string | number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  solid?: boolean,
  onClick?: (e?: BaseButtonEventType) => void,
  title?: string,
  stopPropagation?: boolean,
}

const BaseButton: React.FC<BaseButtonProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get iconVariant() {
      return p.iconVariant ?? (p.size === 'xs' || p.size === 'sm' ? 'filled' : 'regular');
    },
    get icon() {
      const { icon } = p;
      return (
        icon ? <BaseIcon icon={icon} size={p.iconSize} /> : null
      )
    },
    handleClick: (e: BaseButtonEventType) => {
      if (p.stopPropagation) {
        // e.preventDefault();
        e.stopPropagation()
      };
      props.onClick?.(e);
    },
  }))
  return <Observer children={() => (
    <button
      className={
        joinClassName(
          'BaseButton',
          props.className,
          props.solid && 'solid'
        )
      }
      onClick={s.handleClick}
      title={props.title}
    >
      {s.icon}
      {props.children}
    </button>
  )} />
}

export default BaseButton;