import { action, observable, runInAction } from 'mobx';
import React from 'react';
import Dialog from '../../components/Dialog/dialog.constructor';
import ErrorRenderer from '../../components/ErrorRenderer/ErrorRenderer';
import { IS_DEV } from '../../env';
import { AnyObject } from '../../types/base.types';
import { DialogConfig, IDialog } from '../../types/ui/ui.controllers.types';
import { renderRenderable } from '../../utils/components.utils';

export const makeDialogController = () => {

  const c = observable({
    dialogs: [] as IDialog[],
    present: action((d: DialogConfig) => {
      return new Promise((resolve: (d: Promise<any>) => void, reject) => {
        const dialog = new Dialog(d, c);
        runInAction(() => c.dialogs.push(dialog));
        resolve(dialog.promise);
      })
    }),
    success: action((d: DialogConfig) => {
      return c.present({
        // color: ContextColorName.positive,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    attention: action((d: DialogConfig) => {
      return c.present({
        // color: ContextColorName.attention,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    error: action((d: DialogConfig & { error?: AnyObject | string | unknown }) => {
      if (IS_DEV && d.error) console.log(d.error)
      return c.present({
        // color: ContextColorName.alert,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
        ...d.error ? {
          Body: () => <div>
            {renderRenderable(d.body)}
            <ErrorRenderer e={d.error} />
          </div>,
        } : null
      })
    }),
    dismiss: action((d?: IDialog | string) => {
      const index = d ? c.dialogs.findIndex(dialog => typeof d === 'string' ? dialog.config.name === d : dialog === d) : c.dialogs.length - 1;
      const dialog = c.dialogs[index];
      if (!dialog) return;
      if (dialog.status === 'closed') {
        c.dialogs.splice(index, 1);
      } else {
        dialog.close();
      }
    }),
    get hasDialogs(): boolean {
      return c.dialogs.filter(o => o.status === 'opened').length > 0;
    },
    hasDialog: (name: string) => {
      return c.dialogs.find(d => d.config.name === name);
    },
    get lastDialog(): IDialog {
      return c.dialogs[c.dialogs.length - 1];
    },
    reset: action(() => {
      c.dialogs.splice(0);
    })
  })

  return c;

}

export type DialogController = ReturnType<typeof makeDialogController>;