// Do not convert this file to typescript. This is imported into gatsby-config.js, and gatsby-config.js does not support importing tsx.
// Additionally, gatsby-config.js does not support esjs way of importing (e.g. import NAME from 'FILE_PATH';), and will throw "SyntaxError: Cannot use import statement outside a module."
// Originally, this config was intended to be in appContent.constants.tsx, which imports and uses mobx observables, typescript, etc.
// But since there were issues importing that file into gatsby-config.js, this in-the-middle js file was created, so it may be imported into gatsby-config.js and appContent.constants.tsx.
// Since this config file's data is imported into appContent.constants.tsx file, use that file throughout the code instead.

const siteName = "Locations Guild of Ireland";

const APP_GATSBY_CONFIG = {
  siteMetadata: {
    title: siteName,
    description: siteName,
    siteUrl: "https://locationsguildofireland.com",
    author: "AxonDivision",
    keywords: ["ireland", "guild", "film", "movie", "television"],
    lang: "en_IE",
  },
  manifest: {
    name: siteName,
    shortName: siteName,
    description: siteName,
    startUrl: `/`,
    lang: `en`,
    display: `standalone`,
    themeColor: "#ffffff",
    backgroundColor: "#ffffff",
    icon: "src/favicon/locations-ireland/favicon.png",
    icons: [
      {
        src: "src/favicon/locations-ireland/favicon-16x16.png",
        type: "image/png",
        sizes: "16x16",
      },
      {
        src: "src/favicon/locations-ireland/favicon-32x32.png",
        type: "image/png",
        sizes: "32x32",
      },
      {
        src: "src/favicon/locations-ireland/favicon.png",
        type: "image/png",
        sizes: "512x512",
      },
    ],
  },
};

module.exports = APP_GATSBY_CONFIG;
