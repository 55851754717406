import { observable } from 'mobx';

export const getScrollbarWidth = () => {
  return window.innerWidth - document.documentElement.clientWidth;
}

export const getObservableScrollbarWidth = () => {
  const s = observable({
    get windowInnerWidth() { return window.innerWidth; },
    get clientWidth() { return document.documentElement.clientWidth; },
    get scrollbarWidth() { return s.windowInnerWidth - s.clientWidth },
    // get scrollbarWidth() { return window.innerWidth - document.documentElement.clientWidth },
  })
  return s;
}