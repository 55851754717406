import deepmerge from 'deepmerge';
import React from 'react';
import { ProductCardProps } from '../components/ProductCard/ProductCard';
import { IconName } from "../components/Symbols";
import { Renderable } from "./base.types";

export const makeLinkTagConfig = () => {
  return {
    href: '',
    icon: '' as IconName,
    iconSize: 24 as string | number,
    Label: '' as Renderable,
    title: '' as string,
    className: '' as string,
  }
};
export type LinkTagConfig = Partial<ReturnType<typeof makeLinkTagConfig>>;

const makeLogoConfig = () => ({
  enabled: false as boolean,
  Content: <></> as Renderable,
})
type LogoConfig = ReturnType<typeof makeLogoConfig>;

const makeContactDetail = () => ({
  enabled: false as boolean,
  Label: <></> as Renderable,
  type: '' as '' | 'phone' | 'mobile' | 'socials' | 'address' | 'email',
  Separator: <></> as Renderable,
  Value: undefined as Renderable | undefined,
  values: undefined as LinkTagConfig[] | undefined,
})
type ContactDetail = ReturnType<typeof makeContactDetail>;

const makeIconFactory = (icon?: IconName | string) => <></> as Renderable;
type IconFactory = ReturnType<typeof makeIconFactory>;

const makeExplorePageConfig = () => ({
  categoryDefs: {} as { slug: string, name: string }[],
  productCardConfig: {} as {
  } & Omit<ProductCardProps, "product">,
})
type ExplorePageConfig = ReturnType<typeof makeExplorePageConfig>;

type QuoteSectionConfig = {
  enabled: boolean,
  content: {
    sectionClassName: string,
    Logo: Renderable,
    blockquote: {
      icon: IconName | undefined,
      iconSize: number | undefined,
      quote: string,
      quoteAuthor: string,
    },
    ArticleParagraphs: Renderable[],
  }
}

// App Content Config default values used in all sites.
export const makeAppContentConfig = (extraContentConfig?: any) => {
  return deepmerge({
    appInternal: {
      DEV_WP_ORIGIN: "" as string,
      PROD_WP_ORIGIN: "" as string,
      // pageLoadOverridingCSS: (() => { }) as (() => void),
    },
    SEO: {
      fonts: {
        google: {
          href: "" as string,
        },
        typekit: {
          id: "" as string,
        }
      }
    },
    AppLogo: <></> as Renderable,
    appFeatures: {
      wishlist: true,
    },
    nav: {
      logo: makeLogoConfig(),
      discoverMenu: {
        enabled: false as boolean,
      },
      textLinksMenu: {
        enabled: false as boolean,
      },
      showSubmitBtn: {
        enabled: false as boolean,
        linkTag: makeLinkTagConfig(),
      },
      socialMedia: {
        enabled: false as boolean,
        Value: <></> as Renderable,
      },
      contactDetails: [] as ContactDetail[],
      BottomBackground: <></> as Renderable,
    },
    footer: {
      logo: makeLogoConfig() as LogoConfig,
      copyrightText: {
        prefix: "" as string,
        suffix: "" as string,
      },
      contactDetails: [] as ContactDetail[],
      designerInfo: {
        enabled: false as boolean,
        Value: <></> as Renderable,
      },
      BottomBackground: <></> as Renderable,
    },
    baseSeparator: {
      Icon: <></> as Renderable,
    },
    productCard: {
      showCategoryAsPretitle: false as boolean,
    },
    wishlistBtn: {
      navIcon: '' as IconName,
      btnIcon: '' as IconName,
    },
    wishlistScreen: {
      headerTitle: "" as string,
      Paragraphs: <></> as Renderable,
      submitButtonLink: makeLinkTagConfig(),
      viewCatalogueButtonLink: makeLinkTagConfig(),
    },
    siteMetadata: {
      title: "" as string,
      description: "" as string,
      siteUrl: "" as string,
      author: "" as string,
      keywords: [] as string[],
      lang: "" as string,
    },
    page: {
      frontPage: {
        sectionSiteHero: {
          enabled: false as boolean,
          content: {
            Prequote: <></> as Renderable,
            Quote: <></> as Renderable,
            Postquote: <></> as Renderable,
            pointerText: "" as string,
            cornerButtons: {
              leftLinkTag: makeLinkTagConfig(),
              rightLinkTag: makeLinkTagConfig(),
            },
            BottomBackground: <></> as Renderable,
          }
        },
        sectionBackgroundSlide: {
          ArticleContent: <></> as Renderable,
        },
        sectionQuote: [
          {
            enabled: false,
            content: {
              sectionClassName: "",
              Logo: <></>,
              blockquote: {
                icon: undefined,
                iconSize: undefined,
                quote: "",
                quoteAuthor: "",
              },
              ArticleParagraphs: [],
            }
          }
        ] as QuoteSectionConfig[],
        sectionServicesCards: {
          enabled: false as boolean,
          content: {
            header: {
              IconFactory: makeIconFactory,
            },
          },
        },
        sectionToursCards: {
          enabled: false as boolean,
          content: {
            header: {
              IconFactory: makeIconFactory,
            },
          },
        },
        sectionMap: {
          enabled: false as boolean,
        },
        sectionCategoryNavigation: {
          enabled: false as boolean,
        },
        sectionViewAlternator: {
          enabled: false as boolean,
          header: {
            h1Text: "" as string,
            button: makeLinkTagConfig() as LinkTagConfig,
          },
          sectionFrontPageView: {
            contentAlignment: ['right', 'left'] as ('right' | 'left')[],
            IconFactory: makeIconFactory,
            headerAndBodySeparator: <></> as Renderable,
            figcaption: {
              enabled: false as boolean,
              Icon: <></> as Renderable,
            },
          }
        },
        sectionDiscover: {
          enabled: false as boolean,
          categories: {
            row1: null as string[] | null,
            row2: null as string[] | null,
          },
          header: {
            showAsCard: true as boolean,
            Icon: <></> as Renderable,
            title: "" as string,
            button: makeLinkTagConfig() as LinkTagConfig,
          },
          productConfig: {
            canViewDetails: true as boolean,
            hideDetails: false as boolean,
            linkFactory: undefined as ((slug: string) => string) | undefined,
          },
        },
        sectionPictureCollageBanner: {
          enabled: false as boolean,
          Subheading: <></> as Renderable,
          buttons: [] as LinkTagConfig[],
        },
        sectionContentFromPages: {
          enabled: false as boolean,
          header: {
            Icon: <></> as Renderable,
            title: "" as string,
            button: makeLinkTagConfig() as LinkTagConfig,
          }
        },
        sectionAssociations: {
          enabled: false as boolean,
        },
      },
      explore: {
        pageSlugToPageConfig: {} as { [pageSlug in string]: ExplorePageConfig },
      },
      enquiryForm: {
        header: {
          h2Text: "" as string,
          Separator: <></> as Renderable,
        },
        form: {
          countryCode: "" as string,
        }
      },
      contact: {
        content: {
          enquiryText: "" as string,
          contactDetails: [] as ContactDetail[],
        }
      },
      notFound: {
        error: {
          code: "" as string,
          shortname: "" as string,
          description: "" as string,
        }
      }
    },
  }, extraContentConfig ?? {}, { arrayMerge: (target, source) => source })
}
export type AppContentConfig = ReturnType<typeof makeAppContentConfig>;