import { action, flow, observable, reaction } from 'mobx';
import React from 'react';
import { isLegacyEdge } from 'react-device-detect';

import { DisplayMode } from '../constants/displayMode.enum';
import { IS_DEV, isBrowser } from '../env';
import { getObservableScrollbarWidth } from '../scripts/helpers/scrollbar';
import { Nullable } from '../types/base.types';
import { PointerType } from '../types/ui/ui.types';
import { breakpoint } from '../utils/breakpoints.utils';
import { isFirefox, isIE } from '../utils/browsers.utils';
import { getCSSCustomProperty, setCSSCustomProperty } from '../utils/css.utils';
import { debounce } from '../utils/debounce.utils';
import { addRootClass, getScrollY } from '../utils/dom.utils';
import { initParallax } from '../utils/parallax.utils';
import { highPerf } from '../utils/performance.utils';
import PointerDetector from '../utils/pointerDetector.utils';
import { runAfter } from '../utils/waiters.utils';
import { AppController } from './app.controller';
import { makeDialogController } from './ui/dialog.controller';
import { makeOverlayController } from './ui/overlay.controller';
import { makePortalController } from './ui/portal.controller';
import { makeToastController } from './ui/toast.controller';

export const makeUIController = () => {

  const _private = observable({
    _appWidth: isBrowser ? window.innerWidth : 768,
    _appHeight: isBrowser ? window.innerHeight : 768,
  })

  let initHasCalled = false;
  const c = observable({
    ready: false,
    root: null as unknown as AppController,

    DIALOG: makeDialogController(),
    OVERLAY: makeOverlayController(),
    TOAST: makeToastController(),
    PORTAL: makePortalController(),

    menuState: 'closed' as 'open' | 'opening' | 'closed' | 'closing',
    wishlistScreenState: 'closed' as 'open' | 'opening' | 'closed' | 'closing',

    cssFeatures: {
      // grid: false,
      grid: isBrowser ? window.CSS?.supports('display: grid') && !isIE : true,
      customProperties: isBrowser ? window.CSS?.supports('--a: 1') && !isIE : true,
    },
    viewport: {
      width: isBrowser ? document.body.clientWidth : 768,
      height: isBrowser ? window.innerHeight : 768,
      scrollY: isBrowser ? getScrollY() : 0,
    },
    document: {
      width: isBrowser ? document.documentElement.scrollWidth : 768,
      height: isBrowser ? document.documentElement.scrollHeight : 768,
    },
    ui: {
      isDarkTheme: true,
      scrollbarWidth: isBrowser ? window.innerWidth - document.body.clientWidth : 0,
    },
    cursor: {
      x: 0,
      y: 0,
    },
    isFirstLoad: true,

    overrideAppWidth: null as Nullable<number>,
    overrideAppHeight: null as Nullable<number>,
    hasInputFocus: false,
    updateCSSCustomProperties: () => {
      setCSSCustomProperty('--AppWidth', c.appWidth + 'px');
      setCSSCustomProperty('--AppHeight', c.appHeight + 'px');
    },
    measureWindowDimensions: action(() => {
      const appContainer = document.querySelector('.AppContainer');
      _private._appWidth = appContainer?.clientWidth ?? window.innerWidth;
      _private._appHeight = appContainer?.clientHeight ?? window.innerHeight;
      c.updateCSSCustomProperties();
    }),
    get appWidth(): number { return c.overrideAppWidth || _private._appWidth },
    get appHeight(): number { return c.overrideAppHeight || _private._appHeight },
    scrollBarWidth: 0,
    get displayMode(): DisplayMode {
      if (c.appWidth >= breakpoint('desktop')) return DisplayMode.desktop
      if (c.appWidth >= breakpoint('tablet')) return DisplayMode.tablet
      return DisplayMode.phone
    },
    get onlySmallWidthPhones(): boolean { return c.appWidth <= 360 },
    get onlySmallPhones(): boolean { return c.appWidth <= 375 && c.appHeight < 575 },
    get onlyPhones(): boolean { return c.displayMode === 'phone' },
    get fromTablet(): boolean { return c.displayMode !== 'phone' },
    get fromTabletMd(): boolean { return c.appWidth >= breakpoint('tablet-md') },
    get fromTabletLg(): boolean { return c.appWidth >= breakpoint('tablet-lg') },
    get uptoTabletLg(): boolean { return c.appWidth < breakpoint('tablet-lg') },
    get onlyTablets(): boolean { return c.displayMode === 'tablet' },
    get uptoDesktop(): boolean { return c.appWidth < breakpoint('desktop') },
    get uptoDesktopMd(): boolean { return c.appWidth < breakpoint('desktop-md') },
    get fromDesktop(): boolean { return c.displayMode === 'desktop' },
    get fromDesktopMd(): boolean { return c.appWidth >= breakpoint('desktop-md') },
    get fromDesktopLg(): boolean { return c.appWidth >= breakpoint('desktop-lg') },
    get fromDesktopXl(): boolean { return c.appWidth >= breakpoint('desktop-xl') },
    get fromTabletAndTall(): boolean { return c.fromTablet && c.appHeight >= 625 },

    pointerTypes: [] as PointerType[],
    get canTouch(): boolean {
      return c.pointerTypes.includes('touch') || c.pointerTypes.includes('stylus');
    },
    get shouldUseGestures(): boolean {
      return c.pointerTypes.includes('touch') || c.uptoTabletLg;
    },

    vw: isBrowser ? window.innerWidth : 768,
    vh: isBrowser ? window.innerHeight : 768,


    handleToggleMenu() {
      const { classList } = document.getElementById('site-primary-menu')!;
      if (classList.contains('closing')) {
        return;
      }
      if (classList.contains('open')) {
        c.closeMenu();
      } else {
        c.openMenu();
      }
    },
    openMenu: flow(function* () {
      const { classList } = document.getElementById('site-primary-menu')!;
      classList.add('open');
      classList.add('opening');
      document.documentElement.classList.add('menu-open');
      const { WISHLIST } = c.root;
      WISHLIST.closeWishlistScreen();
      if (window.GALLERY_SWIPER) {
        window.GALLERY_SWIPER.pauseAutoPlay();
      }
      // yield wait(1618);
      if (!classList.contains('open')) {
        // check again after await
        classList.remove('opening');
        // window.addEventListener('click', s.autoCloseMenuHandler);
      }
      c.menuState = "open";
    }),
    async closeMenu() {
      const { classList } = document.getElementById('site-primary-menu')!;
      if (!classList.contains('open')) return;
      classList.remove('opening');
      classList.add('closing');
      document.documentElement.classList.remove('menu-open');
      if (window.GALLERY_SWIPER) {
        window.GALLERY_SWIPER.resumeAutoPlay();
      }
      // await wait(618);
      if (classList.contains('open')) {
        classList.remove('closing');
        // check again after await
        classList.remove('open');
      }
      c.menuState = "closed";
    },

    initUISizeDetector: () => {
      const wpAdminBarEl = document.getElementById('wpadminbar');
      let currentAdminBarHeightValue = 0;
      const handler = action(() => {
        const newValue = wpAdminBarEl ? wpAdminBarEl.clientHeight : 0;
        if (currentAdminBarHeightValue !== newValue) {
          setCSSCustomProperty('--wp-admin-bar-height', newValue + 'px');
          currentAdminBarHeightValue = newValue;
        }
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        const oldHeight = parseInt(getCSSCustomProperty('--vh'));
        const diff = newHeight - oldHeight;
        // console.log(diff);
        if (diff > 0 && diff < 100) {
          // console.log('height change too small, ignore');
          return;
        }
        setCSSCustomProperty('--vh', newHeight + 'px');
        setCSSCustomProperty('--vw', newWidth + 'px');
        c.vh = newHeight;
        c.vw = newWidth;
        // c.displayMode = c.vw < 640 ? 'phone' : c.vw < 1024 ? 'tablet' : 'desktop';
      })
      window.addEventListener('load', handler);
      window.addEventListener('resize', handler);
      const scrollHandler = () => {
        setCSSCustomProperty('--transform-origin-y', window.scrollY + (window.innerHeight / 2) + 'px');
      }
      scrollHandler();
      window.addEventListener('scroll', debounce(scrollHandler));
    },

    initHTMLTagScrollbarClass: () => {
      // TODO Make this reactive. But why is mobx reaction not triggering after initial fire.
      // const scrollbar = getObservableScrollbarWidth();
      reaction(
        () => c.ui.scrollbarWidth,
        // () => scrollbar.scrollbarWidth,
        (scrollbarWidth) => {
          if (scrollbarWidth === 0) {
            document.documentElement.classList.add('no-scrollbar');
          } else {
            document.documentElement.classList.add('has-scrollbar');
          }
        },
        { fireImmediately: true }
      )
      // reaction(
      //   () => window.innerWidth,
      //   (width) => console.log("🚀 ~ file: ui.controller.tsx ~ line 203 ~ makeUIController ~ window.innerWidth", window.innerWidth),
      //   { fireImmediately: true }
      // )
      // reaction(
      //   () => document.documentElement.clientWidth,
      //   (width) => console.log("🚀 ~ file: ui.controller.tsx ~ line 208 ~ makeUIController ~ document.documentElement.clientWidth", width),
      //   { fireImmediately: true }
      // )
      IS_DEV && Reflect.set(window, 'scrollbarWidth', getObservableScrollbarWidth())
    },

    init: action((root: AppController) => {
      if (initHasCalled) {
        console.warn('initiating the same UI Controller instance twice. aborting.');
        return;
      }
      c.root = root;
      if (!isBrowser) return;
      c.initUISizeDetector();
      c.measureWindowDimensions();
      if (isBrowser) {
        if (!c.cssFeatures.grid) addRootClass('nogrid');
        if (!c.cssFeatures.customProperties) {
          addRootClass('nocssvar dark');
        }

        document.documentElement.classList.toggle('low-perf', !highPerf);
        document.documentElement.classList.toggle('high-performance', highPerf);
        let lastScrollY = getScrollY();
        const checkScrollY = action(() => {
          c.viewport.scrollY = getScrollY();
          document.documentElement.classList.toggle('page-scrolled', window.scrollY > 0);
          document.documentElement.classList.toggle('reverse-scroll', c.viewport.scrollY < lastScrollY);
          lastScrollY = c.viewport.scrollY <= 0 ? 0 : c.viewport.scrollY;
        })
        const checkScrollbarWidth = action(() => {
          c.ui.scrollbarWidth = window.innerWidth - document.body.clientWidth;
        })
        const handleWindowScroll = () => {
          checkScrollY();
        }
        const handleWindowResize = action(() => {
          c.viewport.width = document.body.clientWidth;
          c.viewport.height = window.innerHeight;
          c.document.width = document.documentElement.scrollWidth;
          c.document.height = document.documentElement.scrollHeight;
          document.documentElement.style.setProperty('--vw', c.viewport.width + 'px');
          document.documentElement.style.setProperty('--vh', c.viewport.height + 'px');
          checkScrollY();
          checkScrollbarWidth();
          c.measureWindowDimensions();
        })
        const handleWindowFocus = () => {
          checkScrollbarWidth();
        }
        if (!isIE && !isFirefox) window.addEventListener('scroll', handleWindowScroll);
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('focus', handleWindowFocus);
        handleWindowResize();
        runAfter(handleWindowResize, 200); // check size briefly after page load, the scrollbar might have appeared
        runAfter(handleWindowResize, 1000); // check again in case the device is slow
        runAfter(handleWindowResize, 2000); // check again in case the device is terribly slow
        const initPointerDetector = () => {
          new PointerDetector({
            onDetectingMouse: action(() => c.pointerTypes.push('mouse')),
            onDetectingTouch: action(() => c.pointerTypes.push('touch')),
            onDetectingStylus: action(() => c.pointerTypes.push('stylus')),
          });
        }

        initPointerDetector();
        initParallax();
        runAfter(action(() => {
          c.isFirstLoad = false;
          c.initHTMLTagScrollbarClass();
        }), 1000);
        runAfter(() => {
          if (isIE) {
            c.DIALOG.present({
              heading: 'You are using Internet Explorer and might not see the page rendered as intended.',
              body: 'We recommend visiting our website with newer browsers.',
            })
          } else if (isLegacyEdge) {
            c.DIALOG.present({
              heading: 'You are using a legacy version of Microsoft Edge and might not see the page rendered as intended.',
              body: 'We recommend visiting our website with the latest version of Microsoft Edge, or other newer browsers.',
            })
          }
          c.initHTMLTagScrollbarClass();
        }, 4000);
        c.ready = true;
      }
    }),
  })
  return c;
}
export type UIController = ReturnType<typeof makeUIController>;