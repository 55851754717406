import { action, makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';

import { ToastController } from '../../controllers/ui/toast.controller';
import { ActionConfig, DialogStatus, IToast, ToastConfig } from '../../types/ui/ui.controllers.types';
import tick from '../../utils/waiters.utils';

export default class Toast implements IToast {

  id = uuid();

  status: DialogStatus = 'beforeOpen';
  config: ToastConfig;
  controller?: ToastController;

  get actions() { return this.config.actions }
  get timeCreated() { return this.config.timeCreated }
  get actionUrl() { return this.config.actionUrl }

  constructor(
    config: ToastConfig,
    controller?: ToastController
  ) {
    this.config = config;
    if (!this.config.name) this.config.name = this.id;
    this.controller = controller;
    this.status = 'opened';
    this.setOpeningState();
    if (config.timeout) this.setTimer();
    makeAutoObservable(this);
  }

  get _closeFromUiStore() {
    return this.controller?.dismiss;
  }

  close() {
    return new Promise<void>(async (resolve, reject) => {
      this.status = 'closing';
      await tick(380);
      runInAction(() => {
        this.status = 'closed';
      });
      resolve();
      this.controller?.dismiss(this);
    })
  }

  setStatus = action((newStatus: DialogStatus) => this.status = newStatus)

  setOpeningState = async () => {
    this.setStatus('opening');
    await tick(880);
    this.setStatus('opened');
  }

  timer?: any;
  setTimer() {
    if (!this.config.timeout || this.config.timeout === Infinity) return;
    this.timer = setTimeout(() => {
      this.close();
    }, this.config.timeout);
  };
  clearTimer() {
    if (!this.timer) return;
    clearTimeout(this.timer);
  }
  _defaultActions: ActionConfig[] = [
    {
      name: 'positive',
      label: 'OK',
      action: () => true,
    },
  ]
}