import { v4 as uuid } from "uuid";
import { Nullable } from "../types/base.types";

export const makeWishlistItem = (v: any, notes?: string) => {
  const base = {
    id: uuid(),
    productId: null as Nullable<string | number>,
    notes: notes || "",
    creationTime: new Date().getTime(),
  }
  if (typeof v === "string" || typeof v === "number") {
    // create new with product id
    base.productId = v;
    return base
  } else if (v instanceof Object) {
    // wrap existing record in WishlistItem class
    base.id = v.id;
    base.productId = v.productId;
    base.notes = v.notes;
    base.creationTime = v.creationTime;
    return base;
  }
  return base;
}
export type WishlistItem = ReturnType<typeof makeWishlistItem>;
