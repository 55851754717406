import { browserVersion, isAndroid, isIOS, isMobile, osVersion } from 'react-device-detect';
import { isBuildTime } from '../env';
import { isFirefox, isIE } from './browsers.utils';

const shouldEnableHighPerformanceAnimations = () => {
  // return false;
  if (isIE) return false;
  if (isFirefox && !isIOS) return false;
  if (isBuildTime) return false;
  const majorVersion = +(osVersion || '').split('.')[0];
  const browserMajorVersion = +(browserVersion || '').split('.')[0];
  // window.alert(`isIOS: ${isIOS}, osVersion: ${osVersion}, browserVersion: ${browserVersion}`)
  if (isMobile && isIOS && (majorVersion >= 13 /** iPhone */ || browserMajorVersion >= 13 /** iPad */)) return true;
  if (isMobile && isAndroid && majorVersion < 9) return false;
  return true;
}

export const highPerf = shouldEnableHighPerformanceAnimations();