// import { loadScript } from "./utils";

import { asyncPrependToContainer, makeElement } from "../utils/dom.utils";
import { log } from "../utils/loggers.utils";

// Google Tag Manager

export const LOAD_HOTJAR = (id: string, sv: string, onLoad?: () => void) => {

  log('LOAD_HOTJAR', "id=", id, ", sv=", sv);

  const noscript = makeElement('noscript');
  const img = makeElement('img');
  img.setAttribute('height', '0');
  img.setAttribute('width', '0');
  img.setAttribute('style', 'display:none;visibility:hidden');

  // @ts-ignore
  (function (h, o, t, j, a, r) {
    // @ts-ignore
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    // @ts-ignore
    h._hjSettings = { hjid: id, hjsv: sv };
    // @ts-ignore
    a = o.getElementsByTagName('head')[0];
    // @ts-ignore
    r = o.createElement('script'); r.async = 1;
    // @ts-ignore
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    // @ts-ignore
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  asyncPrependToContainer(() => noscript, () => document.body);

}