export const isBrowser = typeof window !== "undefined";
export const isBuildTime = !isBrowser;

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const IS_PROD = isProduction;
export const IS_DEV = isDevelopment;

// export const isInCypressTestMode = isBrowser && Boolean(window.Cypress);

const LocalAPIOverrideStorageKey = 'LOCAL_API_OVERRIDE';

export const hostname = isBrowser ? window.location.hostname : '';

export const isLocalhost = hostname === 'localhost';
export const protocol = isLocalhost ? 'http' : 'https';