import { action, observable } from "mobx";
// import moment from "moment";

const getTime = () => new Date().getTime();

const makeClockController = () => {

  const c = observable({
    // now: getTime(),
    // get nowMoment() {
    //   return moment(c.now);
    // }
  });

  // setInterval(action(() => {
  //   c.now = getTime();
  // }), 1000);

  return c;

}

export const CLOCK = makeClockController();

export type ClockController = ReturnType<typeof makeClockController>;