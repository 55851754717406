import './OverlayCloseButton.scss';

import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useControllers } from '../../controllers/app.controller';
import { last } from '../../utils/ramdaEquivalents.utils';
import BaseButton from '../BaseButton/BaseButton';

interface OverlayCloseButtonProps {
  onBeforeClose?: () => boolean | Promise<boolean>,
  onAfterClose?: Function,
  showLabel?: boolean,
  color?: string,
  overlayIdentifier?: string,
}

const OverlayCloseButton: React.FC<OverlayCloseButtonProps> = props => {

  const { UI } = useControllers();

  const handleClose = async () => {
    if (props.onBeforeClose) {
      const beforeCloseGuardResult = await props.onBeforeClose();
      if (!beforeCloseGuardResult) return;
    }
    const topmostOverlay = props.overlayIdentifier ? UI.OVERLAY.getOverlay(props.overlayIdentifier) ?? last(UI.OVERLAY.overlays) : last(UI.OVERLAY.overlays);
    topmostOverlay?.close();
    props.onAfterClose && props.onAfterClose();
  }

  return <Observer children={() => (
    <BaseButton
      onClick={handleClose}
      className="OverlayCloseButton subtle"
      icon="close"
      iconVariant="filled"
      iconSize='1em'
      // size="sm"
      // circle={!props.showLabel}
      // color={props.color}
      // loading={false}
      children={props.showLabel && (props.children ? props.children : 'Close')}
    />
  )} />

}

export default OverlayCloseButton;