import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../types/base.types';
import joinClassNames from '../../utils/className.utils';
import './SocialMediaSites.scss';

type SocialMediaSitesProps = {
  className?: string,
}

const SocialMediaSites: React.FC<SocialMediaSitesProps> = props => {
  const p = props;
  return <div className={joinClassNames("SocialMediaSites", p.className)}>
    {p.children}
  </div>
}

export default SocialMediaSites;