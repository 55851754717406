import { makeAutoObservable, makeObservable, runInAction } from "mobx";
import { v4 as uuid } from 'uuid';
import { OverlayController } from "../../controllers/ui/overlay.controller";
import { IOverlay, OverlayConfig, OverlayStatus } from "../../types/ui/ui.controllers.types";
import tick from "../../utils/waiters.utils";

export default class Overlay implements IOverlay {
  id = uuid();
  config: OverlayConfig;
  status: OverlayStatus = 'beforeOpen';
  controller: OverlayController;
  get name() {
    return this.config.name || this.id;
  }
  constructor(
    config: OverlayConfig,
    controller: OverlayController
  ) {
    this.config = makeObservable(config, { component: false });
    this.config = config;
    this.config.id && (this.id = this.config.id);
    this.controller = controller;
    this.status = 'opened';
    makeAutoObservable(this);
  }
  get noBackdrop() {
    return this.config.noBackdrop;
  }
  close() {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.config.onBeforeClose) {
        const result = await this.config.onBeforeClose();
        if (!result) {
          resolve(false);
          return;
        }
      }
      runInAction(() => this.status = 'closing');
      await tick(380);
      runInAction(() => this.status = 'closed');
      resolve(true);
      this.controller.dismiss(this);
      if (this.config.onAfterClose) {
        this.config.onAfterClose();
      }
    })
  }
}