import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const LocationsGuildOfIrelandSeparatorIconDef = {
  'locations-guild-of-ireland-separator': makeIconDef('locations-guild-of-ireland-separator', {
    regular: () => <svg width="48" height="1" viewBox="0 0 48 1" fill="none">
      <rect x="0.5" width="47" height="1" fill="#E9C65C" />
    </svg>,
  })
}