// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-page-centered-page-tsx": () => import("./../../../src/templates/page-centered-page.tsx" /* webpackChunkName: "component---src-templates-page-centered-page-tsx" */),
  "component---src-templates-page-contact-page-tsx": () => import("./../../../src/templates/page-contact-page.tsx" /* webpackChunkName: "component---src-templates-page-contact-page-tsx" */),
  "component---src-templates-page-content-page-tsx": () => import("./../../../src/templates/page-content-page.tsx" /* webpackChunkName: "component---src-templates-page-content-page-tsx" */),
  "component---src-templates-page-enquiry-tsx": () => import("./../../../src/templates/page-enquiry.tsx" /* webpackChunkName: "component---src-templates-page-enquiry-tsx" */),
  "component---src-templates-page-explore-tsx": () => import("./../../../src/templates/page-explore.tsx" /* webpackChunkName: "component---src-templates-page-explore-tsx" */),
  "component---src-templates-page-front-page-tsx": () => import("./../../../src/templates/page-front-page.tsx" /* webpackChunkName: "component---src-templates-page-front-page-tsx" */),
  "component---src-templates-page-generic-tsx": () => import("./../../../src/templates/page-generic.tsx" /* webpackChunkName: "component---src-templates-page-generic-tsx" */)
}

