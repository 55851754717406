import React from 'react';
import majesticConfigs from './siteConfigs/majesticIrelandRoadTrips.configs';
import marlboroughConfigs from './siteConfigs/escapeToMarlborough.configs';
import locationsConfigs from './siteConfigs/locations.configs';
import { hostname } from '../env';

// const APP_CONTENT_CONFIG = majesticConfigs();
// const APP_CONTENT_CONFIG = marlboroughConfigs();
const APP_CONTENT_CONFIG = locationsConfigs();

export const WP_ORIGIN = (function () {
  switch (hostname) {
    case 'localhost':
      return APP_CONTENT_CONFIG.appInternal.DEV_WP_ORIGIN;
    default:
      return APP_CONTENT_CONFIG.appInternal.PROD_WP_ORIGIN;
  }
})()

export default APP_CONTENT_CONFIG;