import { action, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';

import { IconDefs, IconName, IconVariant } from '.';
import { AnyObject } from '../../types/base.types';
import { removeFromArray } from '../../utils/array.utils';

const s = observable({
  activeIcons: [] as [IconName, IconVariant][],
  get uniqueIcons() {
    const result = {} as AnyObject;
    s.activeIcons.forEach(i => {
      const key = i[0];
      const variant = i[1];
      if (!result[key]) result[key] = [];
      const symbolFc = IconDefs[key]?.[variant];
      if (!symbolFc || result[key].includes(symbolFc)) return;
      result[key].push(symbolFc);
    })
    return result as Record<IconName, (() => React.ReactElement)[]>;
  },
})

export const activateIcon = action((name: IconName, variant: IconVariant) => {
  if (s.activeIcons.find(i => i[0] === name && i[1] === variant)) return;
  s.activeIcons.push([name, variant]);
  return () => removeFromArray(s.activeIcons, name);
});

const style = {
  position: 'fixed',
  top: -24,
  left: -24,
  // width: 24,
  // height: 24,
  overflow: 'hidden',
  opacity: 0,
} as CSSProperties;

const Symbols: React.FC = () => {

  return <Observer children={() => (<div className="Symbols">
    <svg width="24" height="24" viewBox="0 0 24 24" style={style} xmlns="http://www.w3.org/2000/svg">

      <defs>
        {Object.values(s.uniqueIcons).map(syms => syms.map(sym => sym()))}
      </defs>

    </svg>
  </div>
  )} />
}

export default Symbols;