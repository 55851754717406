import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const MenuIconDef = {
  'menu': makeIconDef('menu', {
    // regular: () => <>
    //   <path d="M3 19.4H21" stroke="white" />
    //   <path d="M3 12.2H21" stroke="white" />
    //   <path d="M3 5H21" stroke="white" />
    // </>,
    regular: () => <svg fill="none">
      <line x1="3" y1="6" x2="20" y2="6" stroke="currentColor" strokeWidth="2" />
      <line x1="3" y1="12" x2="15" y2="12" stroke="currentColor" strokeWidth="2" />
      <line x1="3" y1="18" x2="19" y2="18" stroke="currentColor" strokeWidth="2" />
    </svg>,
  })
}
