import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const CollapseIconDef = {
  collapse: makeIconDef('collapse', {
    regular: () => <svg fill="none">
      <path d="M16.59 8.59L12.3532 12.8176C12.158 13.0123 11.842 13.0123 11.6468 12.8176L7.41 8.59L6 10L11.2929 15.2929C11.6834 15.6834 12.3166 15.6834 12.7071 15.2929L18 10L16.59 8.59Z" fill="currentColor" />
    </svg>,
  })
}