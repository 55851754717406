import { action, flow, observable, when } from 'mobx';

import Overlay from '../../components/Overlay/overlay.constructor';
import { IS_DEV } from '../../env';
import { Nillable } from '../../types/base.types';
import { IOverlay, OverlayConfig } from '../../types/ui/ui.controllers.types';
import { last } from '../../utils/ramdaEquivalents.utils';
import tick, { runAfter } from '../../utils/waiters.utils';

export const makeOverlayController = () => {

  const _ = observable({
    isPresenting: false,
  })

  const c = observable({

    firstLoad: true,
    overlays: [] as IOverlay[],
    isTopOverlay: (id: string): boolean => {
      return last(c.overlays)?.id === id ?? false;
    },
    isTopOverlayOfSameType: (o: OverlayConfig): boolean => {
      if (c.overlays.length === 0) return false;
      return last(c.overlays)?.config.type === o.type ?? false;
    },
    hasOverlayIdExact: (id: Nillable<string>) => {
      if (!id) return false;
      return c.overlays.find(overlay => overlay.id === id);
    },
    hasOverlayType: (overlayType: Nillable<string>) => {
      if (!overlayType) return false;
      return c.overlays.find(overlay => overlay.id.startsWith(overlayType));
    },
    hasOverlay: (o: IOverlay | OverlayConfig) => c.hasOverlayIdExact(o.id),
    present: (o: OverlayConfig) => {
      return new Promise<IOverlay>(flow((function* (resolve, reject) {
        yield when(() => !_.isPresenting);
        if (c.hasOverlay(o)) {
          IS_DEV && console.info('Duplicate overlay detected, ABORTING according to specified strategy.', o);
          return;
        }
        //   switch (o.onDuplicate) {
        //     case 'replace':
        //     case 'replaceSimilarTypes':
        //       if (c.isTopOverlayOfSameType(o)) return;
        //       IS_DEV && console.info('Duplicate overlay detected, REPLACING according to specified strategy', o);
        //       c.dismiss(o.id);
        //       yield tick();
        //       break;
        //     case 'abort':
        //       IS_DEV && console.info('Duplicate overlay detected, ABORTING according to specified strategy.', o);
        //       return;
        //     default:
        //       IS_DEV && console.info('Duplicate overlay detected, IGNORING according to specified strategy', o);
        //       return;
        //   }
        // } else if (c.hasOverlayType(o.type)) {
        //   switch (o.onDuplicate) {
        //     case 'replaceSimilarTypes':
        //       if (!c.isTopOverlayOfSameType(o)) {
        //         IS_DEV && console.info('Duplicate overlay type detected, REPLACING according to specified strategy', o);
        //         c.dismiss(o.type, true);
        //         yield tick();
        //       }
        //       break;
        //     default:
        //       break;
        //   }
        // }
        const overlay = new Overlay(o, c);
        _.isPresenting = true;
        c.overlays.push(overlay);
        runAfter(action(() => _.isPresenting = false), 100);
        resolve(overlay);
      })))
    },
    dismiss: action((o?: IOverlay | string, isType: boolean = false) => {
      const index = c.overlays.findIndex(overlay => typeof o === 'string' ? isType ? overlay.config.id?.startsWith(o) : overlay.config.id === o : overlay === o);
      let overlay = c.overlays[index] || last(c.overlays);
      if (!overlay) return;
      if (overlay.status === 'closed') c.overlays.splice(index, 1);
      else overlay.close();
    }),
    getOverlay: (identifier: string | undefined) => {
      return c.overlays.find(o => o.id === identifier);
    },
    get hasOverlays(): boolean {
      return c.overlays.filter(o => o.status === 'opened').length > 0;
    },
    reset: action(() => {
      c.firstLoad = true;
      c.overlays.splice(0);
    })
  })

  return c;

}

export type OverlayController = ReturnType<typeof makeOverlayController>;
