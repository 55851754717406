import { action, observable } from 'mobx';
import ReactDOM from 'react-dom';

import { Renderable } from '../../types/base.types';
import { renderRenderable } from '../../utils/components.utils';

export const makePortalController = () => {

  const c = observable({
    ref: null as React.RefObject<HTMLDivElement> | null,
    registerRef: action((ref: React.RefObject<HTMLDivElement>) => {
      c.ref = ref;
    }),
    render: (renderable: Renderable) => {
      if (!c.ref?.current) {
        return null;
      }
      return ReactDOM.createPortal(
        renderRenderable(renderable),
        c.ref?.current
      )
    },
  })

  return c;

}