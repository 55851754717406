
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useControllers } from '../../controllers/app.controller';
import { Product } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import BaseLink from '../BaseLink/BaseLink';
import './WishlistButton.scss';

type WishlistButtonProps = {
  className?: string,
  asListItem?: boolean,
  asNavButton?: boolean,

  product?: Product,

  doNotShowLabel?: boolean,
  alwaysShowText?: boolean,
  asDiv?: boolean, // used to make BtnView not "tab" focusable.
}

const WishlistButton: React.FC<WishlistButtonProps> = props => {

  const { UI, WISHLIST } = useControllers();

  const p = useProps(props);

  const { appFeatures, wishlistBtn, nav } = APP_CONTENT_CONFIG;
  const { showSubmitBtn } = nav;
  const s = useStore(() => ({
    get navWishlistScreenState() { return UI.wishlistScreenState },
    get wishlist() { return WISHLIST.wishlist },
    get wishlistCount() { return s.wishlist.length },
    get product() { return p.product },
    get isInWishlist() { return s.wishlist.find(w => w.productId === s.product?.id) },
    get buttonIcon() { return s.isInWishlist ? wishlistBtn.btnIcon : 'add' },
    get buttonLabel() { return s.isInWishlist ? 'In Your Wishlist' : 'Add to Wishlist' },
    handleWishlistButtonClick: (e: any) => {
      e.stopPropagation();
      WISHLIST.toggle(s.product);
    },
    handleLinkClick: () => {
      UI.closeMenu();
      WISHLIST.closeWishlistScreen();
    },

    get ListView() {
      return <li className={joinClassNames("wishlist-screen-trigger menu-item")} onClick={WISHLIST.toggleWishlistScreen}>
        <span className={p.className}>Wishlist</span>
        {s.wishlistCount > 0 && <data className="footer-wishlist-button__count visible" value={s.wishlistCount}>{s.wishlistCount}</data>}
      </li>
    },
    get NavBtnView() {
      return <div className='NavWishlistControls'>
        <button id="nav-wishlist-button" className={joinClassNames("NavWishlistBtn", "site-nav__button nav-wishlist-button")} onClick={WISHLIST.toggleWishlistScreen} aria-label='Wishlist Button'>
          <span>
            Wishlist
            {s.wishlistCount > 0 && <data className="nav-wishlist-button__count visible" value={s.wishlistCount}>{s.wishlistCount}</data>}
          </span>
          {s.navWishlistScreenState === 'closing' || s.navWishlistScreenState === 'closed'
            ? <BaseIcon icon={wishlistBtn.navIcon} />
            : <BaseIcon icon='x' />
          }
        </button>
        {showSubmitBtn.enabled && <BaseLink className="NavSubmitEnquiryBtn" to={showSubmitBtn.linkTag.href} title={showSubmitBtn.linkTag.title} onClick={s.handleLinkClick}>
          {showSubmitBtn.linkTag.Label}
        </BaseLink>}
      </div>
    },
    get BtnView() {
      const commonAttr = {
        className: joinClassNames("wishlist-button", s.isInWishlist ? "on" : "", p.alwaysShowText ? "alwaysShowText" : "", p.doNotShowLabel ? "doNotShowLabel" : ""),
        onClick: s.handleWishlistButtonClick,
        children: <>
          <BaseIcon icon={s.buttonIcon} size='1em' />
          <span className="wishlist-button__label">{s.buttonLabel}</span>
        </>,
        'aria-label': 'Wishlist Button'
      }
      return p.asDiv
        ? <div {...commonAttr} />
        : <button {...commonAttr} />
    },
  }))

  return <Observer children={() => (
    appFeatures.wishlist
      ? p.asListItem
        ? s.ListView
        : p.asNavButton ? s.NavBtnView : s.BtnView
      : null
  )} />

}

export default WishlistButton;