import { Observer } from "mobx-react-lite";
import React from "react";
import { useControllers } from "../../controllers/app.controller";
import Overlay from "./Overlay";

const OverlayStack: React.FC = React.memo(() => {
  const { UI } = useControllers();
  return <Observer children={() => <>
    {UI.OVERLAY.overlays.map(o => <Overlay overlay={o} key={o.id} />)}
  </>} />
})

export default OverlayStack;