import { reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { SyntheticEvent } from 'react';

import { useOnMount } from '../../hooks/lifecycle.hooks';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { IconDefs, IconName, IconVariant } from '../Symbols';
import { activateIcon } from '../Symbols/Symbols';

type IconProps = {
  className?: string,
  icon?: IconName,
  variant?: IconVariant,
  onClick?: (e?: SyntheticEvent<SVGElement>) => void,
  size?: string | number,
  opacity?: number,
  disabled?: boolean,
  color?: string,

  width?: string,
  height?: string,
  viewBoxWidth?: string,
  viewBoxHeight?: string,
}

const hasIcon = (name: IconName | undefined, variant: IconVariant) => {
  if (!name) return false;
  return Boolean(IconDefs[name]?.[variant]);
}

const BaseIcon: React.FC<IconProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get name() {
      return p.icon;
    },
    get variant() {
      switch (p.variant) {
        case 'color':
          return hasIcon(s.name, 'color') ? 'color' : hasIcon(s.name, 'filled') ? 'filled' : 'regular';
        case 'filled':
          return hasIcon(s.name, 'filled') ? 'filled' : 'regular';
        default:
          return 'regular';
      }
    },
    get id() {
      return `${s.name}-${s.variant}`;
    },
    get width() {
      return p.width ?? '24';
    },
    get height() {
      return p.height ?? '24';
    },
    get style() {
      return {
        width: p.width ?? p.size ?? '2.4rem',
        height: p.height ?? p.size ?? '2.4rem',
        // color: p.color ?? 'inherit',
        opacity: p.opacity ?? 1,
      }
    },
    get interactable() {
      return Boolean(p.onClick);
    },
    handleClick(e?: SyntheticEvent<SVGElement>) {
      if (p.disabled) return;
      p.onClick && p.onClick(e);
    }
  }));
  useOnMount(() => reaction(
    () => s.id,
    () => s.name && activateIcon(s.name, s.variant),
    { fireImmediately: true }
  ));
  return <Observer children={() => (
    hasIcon(s.name, s.variant) ? <svg
      className={joinClassName(
        'BaseIcon',
        p.className,
        p.disabled ? 'disabled' : '',
        s.interactable ? 'interactable' : '',
      )}
      width={s.width}
      height={s.height}
      viewBox={`0 0 ${p.viewBoxWidth ?? s.width} ${p.viewBoxHeight ?? s.height}`}
      data-name={p.icon}
      onClick={s.handleClick}
      style={s.style}
    >
      <use xlinkHref={`#icon-${s.id}`} />
    </svg> : null
  )} />

}

export default BaseIcon;