import './BaseImage.scss';

import { GatsbyImage, GatsbyImageProps, } from 'gatsby-plugin-image';
import React from 'react';

import { WpMedia } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { usePageSectionContext } from '../PageSection/PageSection';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { action, when } from 'mobx';
import { useStore } from '../../utils/mobx.utils';
import { Observer } from 'mobx-react-lite';

type BaseImageProps = {
  className?: string,
  media?: WpMedia,
  altText?: string,
  src?: string,

  dataAttachmentId?: string,
  dataSrc?: string,
  dataSrcSet?: string,
  dataSizes?: string,

  loadingType?: 'eager' | 'lazy',
  onLoad?: () => void,
  visibilityInViewportOffset?: number,
  loadOnVisible?: boolean,
  animateLoad?: boolean,

  imageType?: 'gatsbyDynamic' | 'rawHTMLTag',
} & Partial<GatsbyImageProps>;

const BaseImage: React.FC<BaseImageProps> = props => {

  const pageSection = usePageSectionContext();

  const p = props;

  const s = useStore(() => ({
    loadOnVisible: p.loadOnVisible ?? true,
    loaded: false as boolean,
    get imageType() {
      return p.imageType ?? 'rawHTMLTag';
    },
    get altText() { return p.altText ?? p.media?.altText ?? "" },
    get dynamicImage() {
      return p.image ?? p.media?.localFile?.childImageSharp?.gatsbyImageData;
    },
    get className() {
      return joinClassNames(
        "BaseImage",
        p.className,
        // !s.loadOnVisible || s.loaded ? "loaded" : "",
        p.animateLoad ? 'animateLoad' : "",
      )
    },
    get commonAttr() {
      return {
        className: s.className,
        alt: s.altText,
        onLoad: p.onLoad,
        objectFit: p.objectFit,
        // ref: ref,
      }
    },
    get imgSrc() {
      return p.src ?? p.dataSrc ?? `${p.media?.mediaItemUrl}` ?? undefined;
    },
    get ImageRenderable() {
      if (s.loadOnVisible && !s.loaded) return null;
      switch (s.imageType) {
        case 'gatsbyDynamic':
          return s.dynamicImage ? <GatsbyImage
            {...s.commonAttr}
            image={s.dynamicImage}
            loading={p.loadingType}
          /> : null;
        case 'rawHTMLTag':
        default:
          return <img
            {...s.commonAttr}
          />;
      }
    },
  }))

  useOnMount(() => {
    if (s.loadOnVisible) {
      return when(
        () => pageSection.visible,
        action(() => {
          if (pageSection.visible) s.loaded = true;
          // console.log('visible', s.loaded, s.className);
        }),
      )
    }
  })

  return <Observer children={() => (
    s.ImageRenderable
  )} />
}

export default BaseImage;