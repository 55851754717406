import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

type LocationsGuildIrelandLogoProps = {
  size?: string,
  width?: string,
  height?: string,
}

const LocationsGuildIrelandLogo: React.FC<LocationsGuildIrelandLogoProps> = props => {

  const { LGILogo } = useStaticQuery(graphql`
  {
    LGILogo: file(absolutePath: {regex: "/images/logo/"}, name: {eq: "logo-lgi"}) {
        id
        name
        ext
        childImageSharp {
          id
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  const p = props;

  return <GatsbyImage
    className="AppLogo LGILogo"
    imgClassName="AppLogoImage LGILogoImage"
    image={LGILogo.childImageSharp.gatsbyImageData}
    style={{
      width: p.width ?? p.size,
      height: p.height ?? p.size,
    }}
    alt={LGILogo.name}
    objectFit="contain"
    loading="eager"
  />
}

export default LocationsGuildIrelandLogo;