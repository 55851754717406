import { Observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';

import { AnyObject, Renderable } from '../types/base.types';
import { isFunction, isObject } from './typeChecks.utils';

export function isReactComponentClass(component: unknown): component is React.ComponentClass {
  return (
    typeof component === 'function' &&
    !!component.prototype?.isReactComponent
  )
}

/**
 * native arrows don't have prototypes; class components have special prototype property 'isReactComponent'
 */
export function isReactFunctionalComponent(component: unknown): component is React.FC {
  if (!component) return false;
  return (
    typeof component === 'function' // can be various things
    && (!component.prototype || !component.prototype?.isReactComponent)
  );
}

export function isReactComponent(component: unknown): component is React.ComponentClass | React.FC {
  return (
    isReactComponentClass(component) ||
    isReactFunctionalComponent(component)
  )
}

export function isLazyComponent<T extends React.FunctionComponent | React.ComponentClass = any>(component: unknown): component is React.LazyExoticComponent<T> {
  return isObject(component) && '$$typeof' in component && component.$$typeof.toString() === 'Symbol(react.lazy)';
}

export function isMemoizedComponent(component: unknown): component is React.NamedExoticComponent {
  return isObject(component) && '$$typeof' in component && component.$$typeof.toString() === 'Symbol(react.memo)';
}

export const renderRenderable = <P extends AnyObject = AnyObject>(R: Renderable<P>, props?: P, fallback?: Renderable) => <Observer children={() => {
  if (isMemoizedComponent(R)) return <R {...props} />;
  if (isLazyComponent(R)) return <Suspense fallback={renderRenderable(fallback) ?? 'Loading...'} children={<R {...props} />} />
  if (isReactComponent(R)) return <R {...props} />;
  if (isFunction(R)) return R(props as any) ?? null;
  return <>{R}</>;
}} />