import React from 'react';

import { AddIconDef } from './Add.icon';
import { ArrowIconDef } from './Arrow.icon';
import { CloseIconDef } from './Close.icon';
import { CollapseIconDef } from './Collapse.icon';
import { HomeIconDef } from './Home.icon';
import { MenuIconDef } from './Menu.icon';
import { NextSliderArrowIconDef } from './NextSliderArrow';
import { PrevSliderArrowIconDef } from './PrevSliderArrow';
import { XIconDef } from './X.icon';

export const CommonIconDefs = {
  ...AddIconDef,
  ...ArrowIconDef,
  ...CloseIconDef,
  ...CollapseIconDef,
  ...HomeIconDef,
  ...MenuIconDef,
  ...XIconDef,
  ...NextSliderArrowIconDef,
  ...PrevSliderArrowIconDef,
} as const