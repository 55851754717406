import React from 'react';

import { IconDef } from '../../types/ui/ui.types';
import { CommonIconDefs } from './iconDefs/common';
import { UniqueIconDefs } from './iconDefs/unique';

export type IconVariant = keyof IconDef;

export const IconDefs = {
  ...CommonIconDefs,
  ...UniqueIconDefs,
} as const

export type IconName = keyof typeof IconDefs;