import { Link } from 'gatsby';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassNames from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './BaseLink.scss';

type BaseLinkProps = {
  to?: string, // internal/external link.
  href?: string, // external link.
  title?: string,
  className?: string,
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void,
  stopPropagation?: boolean,
  preventDefault?: boolean,
  externalInNewTab?: boolean,
}

const BaseLink: React.FC<BaseLinkProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get isExternalLink() {
      return !!p.href || ["http://", "https://", "mailto:", "tel:", "www."].some(str => p.to?.startsWith(str));
    },
    handleOnClick: action((e: React.MouseEvent<HTMLElement>) => {
      if (p.stopPropagation) e.stopPropagation();
      if (p.preventDefault) e.preventDefault();
      p.onClick?.();
    }),
    get commonAttr() {
      return {
        className: joinClassNames(`BaseLink`, p.className),
        children: p.children,
        onClick: s.handleOnClick,
        target: s.isExternalLink && p.externalInNewTab ? '_blank' : undefined,
        rel: s.isExternalLink && p.externalInNewTab ? 'noopener noreferrer' : undefined,
      }
    }
  }));
  const { href, to } = p;
  return <Observer children={() => {
    switch (true) {
      case (s.isExternalLink):
        return <a {...s.commonAttr} href={href || to} />
      case (!!to):
        return <Link {...s.commonAttr} to={to!} />
      default:
        return <span {...s.commonAttr} />
    }
  }}
  />
}

export default BaseLink;