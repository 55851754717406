import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../controllers/app.controller';
import Dialog from './Dialog';
import './DialogStack.scss';

export const DialogStack: React.FC = React.memo(() => {
  const { UI } = useControllers();
  return <Observer children={() => (
    UI.DIALOG.lastDialog ? <Dialog dialog={UI.DIALOG.lastDialog} key={UI.DIALOG.lastDialog.id} /> : null
  )} />
})

export default DialogStack;