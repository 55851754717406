import './BaseSpacer.scss';

import React from 'react';
import joinClassName from '../../utils/className.utils';

interface BaseSpacerProps {
  size?: string,
  inline?: boolean,
}

const BaseSpacer: React.FC<BaseSpacerProps> = props => {

  const p = props;
  const s = {
    get isStandardSize() {
      return !p.size || ['xs', 'sm', 'md', 'ml', 'lg', 'xl'].includes(p.size || '');
    },
    get inline() {
      return p.inline ?? false;
    },
    get style() {
      return {
        width: s.isStandardSize ? undefined : p.size,
        height: s.isStandardSize ? undefined : p.size
      }
    }
  }

  return <div className={
    joinClassName(
      'BaseSpacer',
      s.isStandardSize && (p.size || 'md'),
      s.inline && 'inline',
    )
  } style={s.style} />
}

export default BaseSpacer;