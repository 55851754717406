import './Overlay.scss';

import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useControllers } from '../../controllers/app.controller';
import { useCreateResizeQueryWithRef } from '../../hooks/useCreateResizeQueryWithRef.hook';
import { IOverlay } from '../../types/ui/ui.controllers.types';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OverlayCloseButton from '../OverlayCloseButton/OverlayCloseButton';

interface P {
  overlay: IOverlay,
  index?: number,
}

const Overlay: React.FC<P> = props => {

  const { UI } = useControllers();

  const p = useProps(props);
  const { ref, query: overlayQuery } = useCreateResizeQueryWithRef();

  const s = useStore(() => ({
    overlay: p.overlay,
    config: p.overlay.config,
    get viewStyle() {
      return {
        // ...getContextColorStyle(ContextColor.Primary, p.overlay.config.color),
        paddingTop: UI.fromTablet ? 64 + (p.index ?? 0) * 10 : undefined,
        // '--OverlayHeight': Math.ceil(overlayQuery.height) + 1 + 'px', // add 1px to avoid unnecessary scrollbars
      }
    },
    get overlayStyle() {
      return {
        maxWidth: UI.fromTablet ? s.overlay.config.width : undefined,
      }
    },
    handleBackdropClick: () => {
      if (s.config.canClickOutsideToDismiss) s.overlay.close();
    }
  }));

  const renderComponent = () => {
    return renderRenderable(p.overlay.config.component);
  }

  return <Observer children={() => {
    return <section
      className={joinClassName(
        "OverlayView",
        s.overlay.status,
        s.config.className,
      )}
      style={s.viewStyle}
      data-name={s.config.name}
      data-id={s.overlay.id}
      data-appearance={s.config.appearance || 'sheet'}
      data-cy={s.overlay.config.dataCy}
      key={s.overlay.id}
    >
      {!s.overlay.noBackdrop && <span className="OverlayBackdrop" onClick={s.handleBackdropClick} />}
      <div className="Overlay" style={s.overlayStyle} ref={ref}>
        <div className="OverlayInner">
          <ErrorBoundary>
            {renderComponent()}
          </ErrorBoundary>
        </div>
        <OverlayCloseButton />
      </div>
    </section>
  }} />
}

export default Overlay;