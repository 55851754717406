import deepmerge from "deepmerge";
import { autorun } from "mobx";
import { useControllers } from "../controllers/app.controller";
import { IS_DEV } from "../env";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { AnyObject, Nillable, StringObject } from "../types/base.types";
import { isNil } from "./ramdaEquivalents.utils";
import { isObject, isString } from "./typeChecks.utils";

export type ParamSet<T extends Record<string, Nillable<string>> = Record<string, Nillable<string>>> = T;

export const isValidParamValue = (v: any) => {
  return !!(v !== undefined && v !== null && v !== '' && v !== false);
}

export const makeParamPairs = <T extends StringObject = StringObject>(paramPairs: string[]) => {
  let params: StringObject = {};
  paramPairs.forEach(pair => {
    const arr = pair.split('=');
    params[arr[0]] = arr[1];
  })
  return params as T;
}

export function getUrlParams<T extends StringObject = StringObject>() {
  const location = window.location;
  const paramPairs = location.search ? location.search.replace(/^\?/, '').split('&') : [];
  return makeParamPairs<T>(paramPairs);
  // let params: StringObject = {};
  // paramPairs.forEach(pair => {
  //   const arr = pair.split('=');
  //   params[arr[0]] = arr[1];
  // })
  // return params as T;
}

export function setUrlParams(p: ParamSet, NAVIGATOR?: any, replace?: boolean) {

  const currUrlParams = getUrlParams();
  // if already in url, ignore, we append to end of url later.
  // const excludedUrlParams = Object.entries(currUrlParams).filter(([k,v]) => !(p[k])).reduce((map, [k,v]) => {map[k] = v; return map}, {} as ParamSet);
  // consider strategy: if already exist currently in url, ignore.
  // const excludedSetParams = Object.entries(p).filter(([k,v]) => !(currUrlParams[k])).reduce((map, [k,v]) => {map[k] = v; return map}, {} as ParamSet);
  const urlParams = {
    // ...(replace ? {} : excludedUrlParams),
    ...(replace ? {} : currUrlParams),
    // ...excludedSetParams,
    ...p,
  } as AnyObject;

  const [hashString, ..._] = window.location.hash?.split('?');

  const paramString = Object.entries(urlParams)
    .filter(([key, value]) => isValidParamValue(value))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  const newUrl = [[window.location.pathname, hashString].filter(i => i).join(""), paramString].filter(i => i).join('?');

  if (NAVIGATOR) NAVIGATOR.navigateTo(newUrl);
  else window.history.replaceState(null, '', newUrl);

}

export function removeUrlParam(name?: string, behavior: 'merge' | 'replace' = 'merge', NAVIGATOR?: any) {
  IS_DEV && console.log('Removing url param', name);
  if (!name) return;
  let params = getUrlParams();
  if (Object.keys(params).length === 1 || behavior === 'replace') {
    const { pathname } = window.location;
    if (NAVIGATOR) NAVIGATOR.navigateTo(pathname);
    else window.history.replaceState(null, '', pathname);
    return;
  }
  delete params[name];
  setUrlParams(params, NAVIGATOR, true);
}

export function removeUrlParams(p?: string | string[] | ParamSet, behavior: 'merge' | 'replace' = 'merge', NAVIGATOR?: any) {
  if (!p) return;
  if (p instanceof Array) {
    p.forEach(n => removeUrlParam(n, behavior, NAVIGATOR));
  } else if (p instanceof Object) {
    Object.keys(p).forEach(n => removeUrlParam(n, behavior, NAVIGATOR));
  } else {
    removeUrlParam(p, behavior, NAVIGATOR);
  }
}

export function doesParamExists(paramKey: string) {
  const params = getUrlParams();
  return paramKey in params;
}

export function runIfParamExist(paramKey: string, callback: (paramKey: string, value: string) => unknown) {
  const params = getUrlParams();
  if (paramKey in params) {
    const value = params[paramKey];
    typeof callback === 'function' && callback(paramKey, value);
  }
}

export type ParamValue = boolean | string | number | undefined | null | (string | number)[];
// export type ParamSet = { [key: string]: ParamValue };

export function mapParamSetToString<T extends AnyObject = {}>(params?: T, defaultParams?: T, options?: { allowInfinity?: boolean }) {
  let { allowInfinity } = options || {};
  if (allowInfinity !== false) allowInfinity = true;
  const paramsWithDefault = deepmerge<T>(defaultParams || {}, params || {});
  return Object.entries(paramsWithDefault).map(entry => {
    let key = entry[0];
    let value: ParamValue = entry[1];
    switch (typeof value) {
      case 'boolean': {
        return value ? key : false;
      }
      case 'string':
      case 'number': {
        if (value === Infinity) {
          return `${key}=${allowInfinity ? -1 : 9999}`;
        }
        return `${key}=${value}`;
      }
      case 'object': {
        if (value instanceof Array) {
          return value.filter(i => !isNil(i)).map(v => `${key}[]=${v}`).join('&');
        }
        return '';
      }
      default: {
        return undefined;
      }
    }
  }).filter(i => i).join('&') || undefined;
}

export const useSyncUrlParams = (a: string | ParamSet, b?: any) => {
  // const { NAVIGATOR } = useControllers();
  const NAVIGATOR = undefined;
  return useOnMount(() => {
    if (!isNil(a) && !isNil(b)) {
      const disposer = autorun(() => {
        setUrlParams(isObject(a) ? a : { [a]: b }, NAVIGATOR);
      })
      return () => {
        disposer();
        if (isString(a) && a.startsWith('overlay')) removeUrlParams(a, 'replace', NAVIGATOR);
        else removeUrlParams(a, 'merge', NAVIGATOR);
      }
    }
    // if (!isNil(a) && !isNil(b)) {
    //   const paramExists = (isObject(a) ? Object.keys(a).some((p) => doesParamExists(p)) : doesParamExists(a));
    //   console.log("🚀 ~ file: urlParams.utils.ts ~ line 141 ~ returnuseOnMount ~ paramExists", OVERLAY.firstLoad, paramExists, a)

    //   if (OVERLAY.firstLoad || !paramExists) {
    //     const disposer = autorun(() => {
    //       setUrlParams(isObject(a) ? a : {[a]: b}, NAVIGATOR);
    //     })
    //     return () => {
    //       disposer();
    //       removeUrlParams(a, 'merge', NAVIGATOR);
    //       // removeUrlParams(a, 'replace', NAVIGATOR);
    //     }
    //   }
    // }
  })
}

export const useSyncOverlayURLParams = (name: string) => {
  return useSyncUrlParams('overlay', name);
}
