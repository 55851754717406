import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const HeartIconDef = {
  'heart': makeIconDef('heart', {
    regular: () => <svg viewBox="0 0 24 24" fill="none">
      <g filter="url(#filter0_b_320_7)">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 6.48426C8.51138 1.84753 2.53091 4.24643 3.02928 9.20414C3.35192 12.4136 5.65131 13.9339 7.87724 15.4057C9.63191 16.5659 11.3409 17.6959 12 19.5994V6.48426ZM12 6.48426C15.4886 1.84753 21.4691 4.24643 20.9707 9.20414C20.6481 12.4136 18.3487 13.9339 16.1228 15.4057C14.3681 16.5659 12.6591 17.6959 12 19.5994V6.48426Z" fill="currentColor" />
      </g>
      <defs>
        <filter id="filter0_b_320_7" x="-15" y="-15" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_320_7" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_320_7" result="shape" />
        </filter>
      </defs>
    </svg>,
  })
}