import { isBuildTime } from "../env";
import tick from "./waiters.utils";

export const getFontSize = (element?: HTMLElement, fallback: number = 16) => {
  if (isBuildTime) return fallback;
  return parseFloat(getComputedStyle(element ?? document.body).fontSize);
}

export const disableSmoothScroll = () => {
  if (isBuildTime) return;
  document.documentElement.style.scrollBehavior = 'auto';
  return enableSmoothScroll;
}

export const enableSmoothScroll = () => {
  if (isBuildTime) return;
  document.documentElement.style.scrollBehavior = 'smooth';
}

export const disableSmoothScrollTemporarily = async () => {
  if (isBuildTime) return;
  document.documentElement.style.scrollBehavior = 'auto';
  await tick();
  document.documentElement.style.scrollBehavior = 'smooth';
}

export const getCSSCustomProperty = (name, element = document.documentElement) => {
  const styles = window.getComputedStyle(element);
  return styles.getPropertyValue(name);
}

export const setCSSCustomProperty = (name, value, element = document.documentElement) => {
  element.style.setProperty(name, value);
}

export const removeCSSCustomProperty = (name, element = document.documentElement) => {
  element.style.removeProperty(name);
}
