import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const WishlistIconDef = {
  'wishlist': makeIconDef('wishlist', {
    regular: () => <>
      <rect x="5.5" y="7.5" width="13" height="13" rx="0.5" stroke="currentColor" strokeWidth="1.5" />
      <path d="M15.5 12L15.5 6.5C15.5 4.567 13.933 3 12 3V3C10.067 3 8.5 4.567 8.5 6.5L8.5 12" stroke="currentColor" strokeWidth="1.5" />
    </>,
  })
}
