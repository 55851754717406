import './WishlistScreen.scss';

import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import APP_CONTENT_CONFIG from '../../../constants/appContentConfig.constants';
import { useControllers } from '../../../controllers/app.controller';
import joinClassNames from '../../../utils/className.utils';
import { useStore } from '../../../utils/mobx.utils';
import { autoPluralize } from '../../../utils/string.utils';
import BaseIcon from '../../BaseIcon/BaseIcon';
import ProductCard from '../../ProductCard/ProductCard';
import BaseLink from '../../BaseLink/BaseLink';
import { Nullable } from '../../../types/base.types';
import useAllWpProduct from '../../../content/AllWpProduct/AllWpProduct';

type WishlistScreenProps = {}

const WishlistScreen: React.FC<WishlistScreenProps> = props => {
  const products = useAllWpProduct();

  const { UI, WISHLIST } = useControllers();

  const s = useStore(() => ({
    get wishlist() { return WISHLIST.wishlist },
    get wishlistScreenState() {
      return UI.wishlistScreenState;
    },
    get shouldDisplayWishlistScreen() {
      return UI.wishlistScreenState !== 'closed';
    },
    get count() {
      return s.wishlist.length;
    },
    get countLabel() {
      return autoPluralize(
        s.count,
        '%d item in your Wishlist',
        'No items in your Wishlist yet.',
        '%d items in your Wishlist',
      );
    },
    handleClick: action(() => {
      WISHLIST.closeWishlistScreen();
    }),
  }))

  const getProductById = (id: Nullable<string | number>) => {
    return products.find(p => p.id === id);
  }

  const { wishlistBtn, wishlistScreen } = APP_CONTENT_CONFIG;
  const { submitButtonLink, viewCatalogueButtonLink } = wishlistScreen;
  return <Observer children={() => (
    s.shouldDisplayWishlistScreen ? <section className={joinClassNames("wishlist-screen", s.wishlistScreenState)}>
      <div className="wishlist-screen__inner">
        <article className="wishlist-screen__article">
          <div className="wishlist-screen__article__inner">
            <header>
              <h1>{wishlistScreen.headerTitle}</h1>
            </header>
            <section>
              {wishlistScreen.Paragraphs}
            </section>
            <footer>
              <BaseLink className="button primary" to={submitButtonLink.href} title={submitButtonLink.title} onClick={s.handleClick}>
                <BaseIcon icon={wishlistBtn.navIcon} size={18} />
                <span>{submitButtonLink.Label}</span>
              </BaseLink>
            </footer>
          </div>
        </article>
        <div className="wishlist-screen__list">
          <header className="wishlist-screen__list__header">
            <h2>{s.countLabel}</h2>
          </header>
          <ul className="wishlist-screen__list__inner">
            {s.wishlist.map(item => {
              const product = getProductById(item.productId)
              return product && <li key={item.id}>
                <ProductCard
                  product={product}
                  canViewDetails
                  shouldAnimateIn
                />
              </li>
            })}
            {s.wishlist.length === 0 && <li className="wishlist-screen__view-catalogue-button-wrapper">
              <BaseLink className="wishlist-screen__view-catalogue-button" to={viewCatalogueButtonLink.href} title={viewCatalogueButtonLink.title} onClick={s.handleClick}>
                <BaseIcon icon="arrow" size={18} />
                <span>{viewCatalogueButtonLink.title}</span>
              </BaseLink>
            </li>}
          </ul>
          {s.wishlist.length > 0 && <footer className="wishlist-screen__list__footer">
            <BaseLink className="button primary wishlist-screen__list__footer__submit-query" to={submitButtonLink.href} title={submitButtonLink.title} onClick={s.handleClick}>
              <BaseIcon icon={wishlistBtn.navIcon} size={18} />
              <span>{submitButtonLink.Label}</span>
            </BaseLink>
          </footer>}
        </div>
      </div>
    </section> : null
  )} />
}

export default WishlistScreen;