import React from 'react';

import BaseIcon from '../../components/BaseIcon/BaseIcon';
import BaseSpacer from '../../components/BaseSpacer/BaseSpacer';
import { IconName } from '../../components/Symbols';
import { makeAppContentConfig, AppContentConfig, LinkTagConfig } from '../../types/appContentConfig.types';
import APP_GATSBY_CONFIG from '../appGatsbyConfig.constants';
import { PartialDeep } from 'type-fest';
import BaseLink from '../../components/BaseLink/BaseLink';
import BaseButton from '../../components/BaseButton/BaseButton';
import LocationsGuildIrelandLogo from '../../components/Symbols/symbolDefs/LocationsGuildIrelandLogo.image';
import ScreenGuildIrelandLogo from '../../components/Symbols/symbolDefs/ScreenGuildIrelandLogo.image';
import SocialMediaSites from '../../components/SocialMediaSites/SocialMediaSites';
import './locations.override.scss';

const makeLocationsAppContentConfig = () => {
  const s = {
    get appInternal() {
      return {
        // DEV_WP_ORIGIN: 'http://locations.test',
        DEV_WP_ORIGIN: 'https://wp-locations-ireland.axon.host/',
        PROD_WP_ORIGIN: 'https://wp-locations-ireland.axon.host/',
        // pageLoadOverridingCSS: () => require("./locations.override.scss"),
      }
    },
    get SEO() {
      return {
        fonts: {
          google: {
            // Google Fonts import link.
            href: "",
          },
          typekit: {
            // This Adobe Web Project's fonts/typekit id.
            id: "myk5dpz",
          },
        },
      }
    },
    get AppLogo() {
      return <LocationsGuildIrelandLogo size="92px" />;
    },
    get appFeatures() {
      return {
        wishlist: false,
      }
    },
    get nav() {
      return {
        logo: {
          enabled: true,
          Content: s.AppLogo,
        },
        socialMedia: {
          enabled: true,
          Value: s.footer!.contactDetails![s.footer!.contactDetails!.length - 1]?.Value,
        },
      }
    },
    get footer() {
      return {
        logo: {
          enabled: false,
          Content: s.AppLogo,
        },
        copyrightText: {
          prefix: "",
          suffix: "",
        },
        contactDetails: [
          {
            enabled: false,
            Label: 'Phone',
            type: 'phone',
            values: [
              {
                title: `Call us`,
                href: `tel:00 11 22 33 44`,
                Label: <strong>00 11 22 33 44</strong>,
              },
            ] as LinkTagConfig[],
          },
          {
            enabled: true,
            Label: 'Email',
            type: 'email',
            values: [
              {
                title: `Email us`,
                href: "mailto:locationsguildireland@gmail.com",
                Label: <strong>locationsguildireland<wbr/>@gmail.com</strong>,
              }
            ] as LinkTagConfig[],
          },
          {
            enabled: true,
            Label: <></>,
            type: 'socials',
            Value: <>
              <BaseLink href="https://www.instagram.com/locations_guild_of_ireland/" title="Locations Guild of Ireland Instagram Account" externalInNewTab>
                <BaseIcon icon="instagram" />
              </BaseLink>
              <BaseLink href="https://www.facebook.com/locationsguildireland/" title="Locations Guild of Ireland Facebook Page" externalInNewTab>
                <BaseIcon icon="facebook" />
              </BaseLink>
              <BaseLink href="https://mobile.twitter.com/locationguild/" title="Locations Guild of Ireland Twitter Account" externalInNewTab>
                <BaseIcon icon="twitter" />
              </BaseLink>
            </>,
          }
        ],
        designerInfo: {
          enabled: true,
          Value: <>
            <BaseLink href="https://www.axondivision.com/" externalInNewTab>Site Designed by AxonDivision</BaseLink>
          </>,
        },
        BottomBackground: <>
          <LocationsGuildIrelandLogo size="186px" />
        </>,
      }
    },
    get baseSeparator() {
      return {
        Icon: <BaseIcon icon="locations-guild-of-ireland-separator" width="48" />
      }
    },
    get productCard() {
      return {
        showCategoryAsPretitle: true,
      }
    },
    get siteMetadata() {
      return APP_GATSBY_CONFIG.siteMetadata;
    },
    get page() {
      return {
        frontPage: {
          sectionSiteHero: {
            enabled: true,
            content: {
              Quote: <>
                <div>Locations Guild</div>
                <div>of Ireland</div>
              </>,
              Postquote: <>
                <p>{"Representing Film & Television Locations Personnel in Ireland"}</p>

                <BaseSpacer />

                <BaseLink className="" to="/contact">
                  <BaseButton className="solid">Contact Us</BaseButton>
                </BaseLink>
                <BaseSpacer inline size="0.8em" />
                <BaseLink className="" to="/how-to-join">
                  <BaseButton>Work with Us</BaseButton>
                </BaseLink>
              </>,
            }
          },
          sectionQuote: [
            {
              enabled: true,
              content: {
                sectionClassName: "WelcomeQuote",
                Logo: <LocationsGuildIrelandLogo />,
                blockquote: {
                  icon: '' as IconName,
                  quote: "Welcome",
                  quoteAuthor: "",
                },
                ArticleParagraphs: [
                  <p>
                    Thanks for taking the time to visit us here at LGI. Our Guild represents Location Managers, Assistant Location Managers Assistants and Trainees operating in Ireland. We aim to enhance the service provided to domestic and international Producers & Production Companies, and improve standards by working as a co-operative association.
                  </p>,
                  <p>
                    Members have experience of International & National Film & Television production – Feature Film, TV Series, Commercials, Music Videos and Documentaries. A nationwide service is provided, and members can advise what part of Ireland best suits your production. If you are a Production Company or Producer requiring an availability check on Locations Crew for hire, you can email us with your production details, we can then post your job to all our members.
                  </p>,
                  <p>
                    If you wish to contact a specific Locations person with regard to a production then use the Members Page to view who we are. Members listings, contact details & a brief overview of their recent experience is available here.
                  </p>,
                ]
              }
            },
            {
              enabled: true,
              content: {
                sectionClassName: "SGIQuote",
                Logo: <ScreenGuildIrelandLogo />,
                blockquote: {
                  icon: '' as IconName,
                  quote: "Screen Guilds of Ireland",
                  quoteAuthor: "",
                },
                ArticleParagraphs: [
                  <p>
                    SGI represents film and television workers across the many departments within the Film and Television Industry in Ireland with the aim of establishing and promoting a fair, transparent and rewarding working environment for all workers engaged in the industry, spanning national and international productions of all scales and genres.
                  </p>,
                  <p>
                    <strong>Locations Guild of Ireland is supported by & in partnership with Screen Guilds Ireland</strong>
                  </p>,
                  <SocialMediaSites>
                    <BaseLink href="https://www.sgi.ie/" title="Screen Guilds Ireland Website" externalInNewTab>
                      <BaseIcon icon="home" />
                    </BaseLink>
                    <BaseLink href="https://www.instagram.com/screenguilds/" title="Screen Guilds Ireland Instagram Account" externalInNewTab>
                      <BaseIcon icon="instagram" />
                    </BaseLink>
                    <BaseLink href="https://twitter.com/screenguilds" title="Screen Guilds Ireland Twitter Account" externalInNewTab>
                      <BaseIcon icon="twitter" />
                    </BaseLink>
                    <BaseLink href="https://www.linkedin.com/in/screen-guilds-ireland-33b219213/" title="Screen Guilds Ireland LinkedIn Page" externalInNewTab>
                      <BaseIcon icon="linkedin" />
                    </BaseLink>
                  </SocialMediaSites>,
                ]
              }
            }
          ],
          sectionViewAlternator: {
            enabled: true,
            header: {
              h1Text: "Locations",
              button: {
                href: '/locations',
                icon: "arrow",
                iconSize: '1em',
                Label: 'View All'
              } as LinkTagConfig,
            },
            sectionFrontPageView: {
              contentAlignment: ['left', 'right'] as ('right' | 'left')[],
              IconFactory: (icon: IconName) => <BaseIcon icon={icon} size={90} />,
              headerAndBodySeparator: <BaseSpacer size='1em' />,
            }
          },
          sectionDiscover: {
            enabled: false,
            categories: {
              // row1: ['location-manager', 'assistant-location-manager', 'location-assistant', 'trainee'],
            },
            header: {
              showAsCard: false,
              Icon: <></>,
              title: "Members Directory",
              button: {
                href: '/members',
                icon: "arrow",
                iconSize: '1em',
                Label: 'View All'
              } as LinkTagConfig,
            },
            productConfig: {
              canViewDetails: false,
              hideDetails: true,
              linkFactory: (slug: string) => `/members/?viewProductId=${slug}`,
            },
          },
          sectionPictureCollageBanner: {
            enabled: true,
            Subheading: <>
              <p>The Locations Guild of Ireland encourages and supports a culture of continuous learning and development within the profession. We have a Skills Development sub-committee to facilitate skills needs analysis for the various grades in our profession and continued development of up-to-date competency frameworks across the grades.</p>
              <p>The output from this process and feedback from Guild members is used by LGI to co-ordinate with Screen Guilds Ireland, Screen Skillnets and other relevant bodies to develop and implement skills development programmes, both hands on and academic, to enable our members to achieve and maintain the very high standards of professional performance with which the Irish film industry is synonymous.</p>
            </>,
            buttons: [
              {
                href: '/contact',
                Label: 'Contact Us',
                className: 'solid',
              },
            ] as LinkTagConfig[],
          },
          sectionContentFromPages: {
            enabled: true,
            header: {
              Icon: <></>,
              title: "Filming in Ireland",
              button: {
                href: '/members',
                icon: "arrow",
                iconSize: '1em',
                Label: 'View All'
              } as LinkTagConfig,
            }
          },
          sectionAssociations: {
            enabled: true,
          }
        },
        explore: {
          pageSlugToPageConfig: {
            'members': {
              categoryDefs: [
                { slug: 'location-manager', name: 'Location Manager' },
                { slug: 'assistant-location-manager', name: 'Assistant Location Manager' },
                { slug: 'location-assistant', name: 'Location Assistant' },
                { slug: 'trainee', name: 'Trainee' },
              ],
            },
            'locations': {
              categoryDefs: [
                { slug: 'castle', name: 'Castle' },
                { slug: 'coastal', name: 'Coastal' },
                { slug: 'landscape', name: 'Landscape' },
                { slug: 'period', name: 'Period' },
                { slug: 'rural', name: 'Rural' },
                { slug: 'urban', name: 'Urban' },
              ],
              productCardConfig: {
                showSubheadingAsPretitle: true,
                overlayWidth: "90%",
                overlayAppearance: 'card',
                doNotShowBody: true,
                orientation: 'landscape',
              },
            }
          }
        },
        expressInterest: {
          header: {
            h2Text: "",
            Separator: <></>,
          },
          form: {
            countryCode: "IE",
          }
        },
        contact: {
          content: {
            enquiryText: 'For all enquiries',
            contactDetails: s.footer!.contactDetails,
          }
        },
        notFound: {
          error: {
            code: '404',
            shortname: 'Page not found',
            description: 'We could not find a page matching your URL :(',
          }
        }
      }
    }
  } as PartialDeep<AppContentConfig>;
  return makeAppContentConfig(s) as AppContentConfig;
}

export default makeLocationsAppContentConfig;