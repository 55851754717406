import { isNil } from "./ramdaEquivalents.utils";

export const toLowerCase = (s: string) => isNil(s) ? '' : s.toLowerCase();

export function fromCharCode(s) {
  return s.replace(/&#(\d{1,4});/g, (fullString, code) => String.fromCharCode(code));
}

export function normalizeString(input) {
  return (input || '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function isPlural(input) {
  return /[isz]es|[a-rt-z]s$/.test(input);
}
export function pluralize(input) {
  if (!input)
    return '';
  if (isPlural(input))
    return input;
  switch (input.substr(input.length - 1)) {
    case 's': return input + 'es';
    case 'y': return input.substr(0, input.length - 1) + 'ies';
    case 'z': return input + 'zes';
    default: return input + 's';
  }
}

export function autoPluralize(
  amount: number,
  singularString: string,
  zeroString?: string,
  pluralString?: string,
  doNotAutoDisplayNumber?: boolean,
) {
  if (!amount && zeroString) return zeroString;
  if (amount === 1) {
    if (singularString.includes('%d')) {
      return singularString.replace(/%d/, '1');
    }
    if (doNotAutoDisplayNumber) return singularString;
    return '1 ' + singularString;
  } else {
    const _pluralString = pluralString || pluralize(singularString);
    if (_pluralString) {
      if (_pluralString.includes('%d')) {
        return _pluralString.replace(/%d/, amount + '');
      }
      if (doNotAutoDisplayNumber) return _pluralString;
      return amount + ' ' + _pluralString;
    }
  }
  return amount;
}