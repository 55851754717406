import React from 'react';

import { makeIconDef } from '../../makeIconDef';

export const ArrowIconDef = {
  arrow: makeIconDef('arrow', {
    regular: () => <svg fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M12 2L22 12L12 22" />
      <path d="M2 12H22" />
    </svg>,
  })
}