import './ProductCard.scss';

import { action, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import { useControllers } from '../../controllers/app.controller';
import { pipe } from '../../scripts/helpers/pipe';
import { WpProduct } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { fromCharCode } from '../../utils/string.utils';
import BaseImage from '../BaseImage/BaseImage';
import { presentOverlayProduct } from '../OverlayProduct/OverlayProduct';
import WishlistButton from '../WishlistButton/WishlistButton';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import BaseLink from '../BaseLink/BaseLink';

export type ProductCardProps = {
  className?: string,
  product: WpProduct,
  shouldAnimateIn?: boolean,
  showIrishName?: boolean,
  showPrice?: boolean,
  openProductOverlayOnMount?: boolean,
  canViewDetails?: boolean,
  hideDetails?: boolean,
  linkFactory?: (slug: string) => string,
  showSubheadingAsPretitle?: boolean,
  overlayWidth?: string | number,
  overlayAppearance?: 'card' | 'sheet',
  doNotShowBody?: boolean,
  orientation?: 'portrait' | 'landscape',
  doNotShowHoverEffect?: boolean,
}

const ProductCard: React.FC<ProductCardProps> = props => {

  const { UI, WISHLIST } = useControllers();

  const p = useProps(props);

  const { productCard: productCardConfig } = APP_CONTENT_CONFIG;
  const s = useStore(() => ({
    wishlist: WISHLIST.wishlist,
    imageLoaded: false,
    get product() { return p.product },
    get isInWishlist() {
      return s.wishlist.find(w => w.productId === s.product.id);
    },
    get featuredImage() { return s.product.featuredImage?.node },
    get categories() { return s.product.categories?.nodes ?? [] },
    get locations() { return s.product.productFields?.locations ?? [] },
    get isTour() {
      return s.categories.find(c => c.slug === 'tours-trips');
    },
    get county() {
      return s.locations?.find(l => l.location?.categories?.nodes?.find(c => c.slug === 'counties'));
    },
    get city() {
      return s.locations?.find(l => l.location?.categories?.nodes?.find(c => c.slug === 'cities'));
    },
    get countyName() {
      return s.renderString(s.county ? (s.county.location?.title || '').replace('Co. ', '') : '');
    },
    get cityName() {
      return s.renderString(s.city ? (s.city.location?.title || '') : '');
    },
    get category() {
      return s.renderString(s.categories[0]?.name ?? '');
    },
    get pretitle() {
      if (p.showSubheadingAsPretitle) return s.product.productFields?.subheading ?? "";
      if (productCardConfig.showCategoryAsPretitle && s.categories && s.categories.length > 0) return s.category;
      return s.renderString(s.cityName || s.countyName);
    },
    get shouldShowPretitle() {
      return s.pretitle && s.pretitle !== s.title;
    },
    get title() {
      return s.renderString(s.product.title);
    },
    get irishName() {
      return s.renderString(s.product.productFields?.irishName);
    },
    get priceInfo() {
      const price = p.product.productFields?.price;
      // if (price.length > 8 && price.includes('quote') && p.shortenPrice) return "Contact for quote";
      // if (price.includes('quote')) return price;
      if (!price || price?.includes('quote')) return '';
      return `$ ${price}`
    },
    onLoad: action(() => {
      s.imageLoaded = true;
    }),
    renderString(value: string) {
      return value ? pipe(fromCharCode, sanitizeHtml)(value) : "";
    },
    handleComponentClick() {
      if (p.canViewDetails) s.viewDetails();
    },
    viewDetails() {
      if (!p.canViewDetails) return;
      presentOverlayProduct(s.product, UI, {
        showSubheadingAsPretitle: p.showSubheadingAsPretitle,
      }, {
        overlayWidth: p.overlayWidth,
        overlayAppearance: p.overlayAppearance,
        doNotShowBody: p.doNotShowBody,
      });
    },
    get ProductCardInner() {
      return <div className="ProductCard__inner">
        {!p.hideDetails && <header className="ProductCard__header">
          {s.shouldShowPretitle && <p className="ProductCard__pretitle" >{s.pretitle}</p>}
          <h3 className="ProductCard__title">{s.title}</h3>
          {p.showIrishName && s.irishName && <p className="ProductCard__irish-title">{s.irishName}</p>}
          {p.showPrice && s.priceInfo && <p className="ProductCard__price">{s.priceInfo}</p>}
        </header>}
        <figure className="ProductCard__figure">
          {s.featuredImage && <BaseImage
            className={joinClassNames(`filler ProductCard__figure__image-filler`, s.category ? s.category : "")}
            media={s.featuredImage}
            onLoad={s.onLoad}
            imageType='gatsbyDynamic'
          />}
        </figure>
        <footer>
          <WishlistButton product={s.product} />
        </footer>
      </div>
    },
  }))

  useOnMount(() => {
    const disposer = reaction(
      () => Boolean(p.openProductOverlayOnMount),
      (shouldOpenOverlay) => shouldOpenOverlay && s.viewDetails(),
      { fireImmediately: true }
    )
    return disposer;
  })

  return <Observer children={() => (
    <div className={joinClassNames(
      "ProductCard",
      p.canViewDetails ? "interactable" : "",
      p.shouldAnimateIn ? "shouldAnimateIn" : "",
      s.imageLoaded ? "imageLoaded" : "",
      p.className,
      p.orientation ?? 'portrait',
      !p.doNotShowHoverEffect ? "" : "noHoverEffect",
    )}
      data-product-id={s.product.id}
      onClick={s.handleComponentClick}
    >
      {p.linkFactory
        ? <BaseLink
          to={p.linkFactory(s.product.slug)}
          title={s.product.title}
        >
          {s.ProductCardInner}
        </BaseLink>
        : s.ProductCardInner
      }
    </div>
  )} />
}

export default ProductCard;