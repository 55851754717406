import { action, flow, observable, runInAction } from 'mobx';

import Toast from '../../components/Toast/toast.constructor';
import { DisplayMode } from '../../constants/displayMode.enum';
import { Renderable } from '../../types/base.types';
import { ActionConfig, IToast, ToastConfig } from '../../types/ui/ui.controllers.types';


export const makeToastController = () => {

  const c = observable({

    uiController: undefined as {
      displayMode: DisplayMode,
    } | undefined,

    toasts: [] as IToast[],

    present: action((t: ToastConfig) => {
      return new Promise<Toast>(flow(function* (resolve) {
        const config = { ...t }
        if (config.wait) yield config.wait();
        const toast = new Toast(config, c);
        runInAction(() => c.toasts.push(toast));
        resolve(toast);
      }))
    }),

    toast: action((
      message: Renderable,
      // colorCodedState?: ColorCodedState,
      timeout: number = 5000,
      // icon?: IconName,
      actions?: ActionConfig[],
    ) => c.present({ heading: message, timeout, actions })),
    // ) => c.present({heading: message, colorCodedState, timeout, icon, action})),

    // positive: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.positive, timeout, icon, action)),
    // success: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.positive, timeout, icon, action)),
    // neutral: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.neutral, timeout, icon, action)),
    // attention: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.attention, timeout, icon, action)),
    // alert: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.alert, timeout, icon, action)),
    // error: action((message: Renderable, timeout?: number, icon?: IconName, action?: GenericFunction) => c.toast(message, ColorCodedState.error, timeout, icon, action)),

    dismiss: action((t: IToast | string) => {
      const index = c.toasts.findIndex(toast => typeof t === 'string' ? toast.config.name === t : toast === t);
      const toast = c.toasts[index];
      if (toast) {
        if (toast.status === 'closed') {
          c.toasts.splice(index, 1);
        } else {
          toast.close();
        }
      }
    }),
    get hasToasts(): boolean {
      return c.toasts.filter(o => o.status === 'opened').length > 0;
    },
    hasToast: (id: string) => {
      return c.toasts.find(t => t.id === id);
    },
    reset: action(() => {
      c.toasts.forEach(t => t.close());
    })
  })

  return c;

}

export type ToastController = ReturnType<typeof makeToastController>;