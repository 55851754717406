import React from 'react';

import { CelticSymbolCrossIconDef } from './CelticSymbolCross.icon';
import { CloverIconDef } from './Clover.icon';
import { FacebookIconDef } from './Facebook.icon';
import { HarpIconDef } from './Harp.icon';
import { HeartIconDef } from './Heart.icon';
import { InstagramIconDef } from './Instagram.icon';
import { LinkedInIconDef } from './LinkedIn.icon';
import { LocationsGuildOfIrelandSeparatorIconDef } from './LocationsGuildIrelandSeparator.icon';
import { TwitterIconDef } from './Twitter.icon';
import { WishlistIconDef } from './Wishlist.icon';

export const UniqueIconDefs = {
  ...CelticSymbolCrossIconDef,
  ...CloverIconDef,
  ...FacebookIconDef,
  ...HarpIconDef,
  ...HeartIconDef,
  ...InstagramIconDef,
  ...LinkedInIconDef,
  ...LocationsGuildOfIrelandSeparatorIconDef,
  ...TwitterIconDef,
  ...WishlistIconDef,
} as const