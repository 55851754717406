import { Link } from "gatsby";
import React, { ReactNode } from 'react';
import { useGetDataAttributes } from "../../hooks/useGetDataAttributes.hook";
import { TransitionTrigger } from "../../types/transitions.types";
import joinClassName from "../../utils/className.utils";
import { getScrollY } from "../../utils/dom.utils";
import { highPerf } from "../../utils/performance.utils";
import { AlwaysFalseFn } from "../../utils/ramdaEquivalents.utils";

interface AnimatedLinkProps {
  className?: string,
  to: string,
  title: string,
  length?: number,
  onClick?: () => void,
  activeClassChecker?: () => boolean,
  target?: string,
  rel?: string,
  children?: ReactNode
}

/**
 * NOTE: Due to it also animating the NavBar, this is reverted back to a Link that doesn't animate.
 *
 * A wrapper around the TransitionLink component, provides basic fading effect for entering and exiting pages.
 */
export const AnimatedLink = React.forwardRef((props: React.PropsWithChildren<AnimatedLinkProps>, ref) => {
  const dataAttr = useGetDataAttributes(props);
  const commonAttr = {
    className: joinClassName(props.className, (props.activeClassChecker ?? AlwaysFalseFn)() ? 'active' : undefined),
    to: props.to,
    title: props.title,
    length: props.length,
    children: props.children,
    onClick: props.onClick,
    activeClassName: "active",
    target: props.target,
    rel: props.rel,
    ...dataAttr,
  }
  return <Link {...commonAttr} />
})