import './OverlayProduct.scss';

import { Observer } from 'mobx-react-lite';
import React from 'react';

import { UIController } from '../../controllers/ui.controller';
import { Product } from '../../types/wordpress.types';
import { useProps, useStore } from '../../utils/mobx.utils';
import { sanitizeAllowExtra } from '../../utils/sanitize.utils';
import ProductCard, { ProductCardProps } from '../ProductCard/ProductCard';
import WishlistButton from '../WishlistButton/WishlistButton';
import { useSyncUrlParams } from '../../utils/urlParams.utils';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';

export const ID_OverlayProduct = 'viewProductId'

export const presentOverlayProduct = (
  product: Product,
  UI: UIController,
  productConfig?: Omit<ProductCardProps, 'product'>,
  options?: {
    overlayWidth?: string | number,
    overlayAppearance?: 'card' | 'sheet',
    doNotShowBody?: boolean,
  },
) => {
  UI.OVERLAY.present({
    component: <OverlayProduct
      product={product}
      doNotShowBody={options?.doNotShowBody}
      productConfig={productConfig}
    />,
    name: `${ID_OverlayProduct}-${product.id}`,
    width: options?.overlayWidth ?? '82.5rem',
    canClickOutsideToDismiss: true,
    appearance: options?.overlayAppearance,
  })
}

type OverlayProductProps = {
  product: Product,
  doNotShowBody?: boolean,
  productConfig?: Omit<ProductCardProps, 'product'>,
}

const OverlayProduct: React.FC<OverlayProductProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get product() { return p.product },
    get body() { return s.product.content },
  }))

  useSyncUrlParams(ID_OverlayProduct, s.product.slug);

  const { appFeatures } = APP_CONTENT_CONFIG;
  return <Observer children={() => (
    <div className="OverlayProduct">
      <ProductCard
        product={s.product}
        {...props.productConfig}
        showIrishName
        showPrice
        doNotShowHoverEffect
      />
      {!p.doNotShowBody && <>
        <article className="OverlayProduct__article">
          <section dangerouslySetInnerHTML={{ __html: sanitizeAllowExtra(s.body, [], { allowedAttributes: { 'div': ['style'] } }) }} />
          {appFeatures.wishlist && <p><em>Interested in <strong>{s.product.title}</strong>? Add it to your wishlist so that you don't miss it!</em></p>}
        </article>
        <footer className="OverlayProduct__footer">
          <WishlistButton product={s.product} alwaysShowText />
          {/* <BaseLink className="OverlayProduct__footer__admin-edit-button" href={`${WP_ORIGIN}/wp-admin/post.php?post=${s.product.id}&action=edit`} title="Edit this product">Edit this product</BaseLink> */}
        </footer>
      </>}
    </div>
  )} />
}

export default OverlayProduct;