import { action, flow, observable, toJS } from 'mobx';

import { makeWishlistItem, WishlistItem } from '../models/makeWishlistItem.model';
import { WpProduct } from '../types/wordpress.types';
import { AppController } from './app.controller';
import { StoreController } from './store.controller';
import { UIController } from './ui.controller';
import APP_CONTENT_CONFIG from '../constants/appContentConfig.constants';

const { navIcon, btnIcon } = APP_CONTENT_CONFIG.wishlistBtn;
export const makeWishlistController = () => {

  let initHasCalled = false;
  const c = observable({
    ready: false,
    root: null as unknown as AppController,
    wishlist: [] as WishlistItem[],
    get STORE(): StoreController {
      return c.root.STORE;
    },
    get UI(): UIController {
      return c.root.UI;
    },
    init: action((root: AppController) => {
      if (initHasCalled) {
        console.warn('initiating the same WISHLIST Controller instance twice. aborting.');
        return;
      }
      c.root = root;
      c.ready = true;

      const storedWishlist = c.STORE.get('wishlist');
      c.wishlist.push(...(storedWishlist instanceof Array ? storedWishlist : []));
    }),
    save() {
      c.STORE.set('wishlist', JSON.stringify(toJS(c.wishlist)));
    },
    indexOfProduct: (product: WpProduct) => {
      return c.wishlist.findIndex(w => w.productId === product.id);
    },
    has: (product: WpProduct) => {
      return c.indexOfProduct(product) >= 0;
    },
    toggle: (product: WpProduct | undefined) => {
      if (!product) return;
      if (c.has(product)) c.remove(product);
      else c.add(product);
    },
    removeByIndex: (index: number) => {
      c.wishlist.splice(index, 1);
      c.save();
    },
    add: (product: WpProduct) => {
      const index = c.indexOfProduct(product);
      if (index >= 0) return false;
      c.wishlist.push(makeWishlistItem(product.id));
      c.save();
      c.UI.TOAST.present({
        name: `added-to-wishlist-${product.id}`,
        icon: btnIcon,
        heading: `${product.title} has been added to your wishlist.`,
        color: 'green',
        timeout: 4000,
      })
      return true;
    },
    remove: (product: WpProduct) => {
      c.UI.TOAST.present({
        name: `removed-from-wishlist-${product.id}`,
        icon: navIcon,
        heading: `${product.title} has been removed from your wishlist.`,
        color: 'primary',
        timeout: 4000,
        actions: [
          {
            name: 'undo',
            label: 'Undo',
            action: () => {
              c.add(product);
            },
          },
        ]
      })
      let index = c.indexOfProduct(product);
      if (index < 0) return false;
      while (index >= 0) {
        c.removeByIndex(index);
        index = c.indexOfProduct(product);
      }
      return true;
    },
    clear() {
      c.wishlist.splice(0);
      c.save();
      return c.wishlist;
    },


    openWishlistScreen: action(() => {
      if (c.UI.wishlistScreenState === 'closed') {
        c.UI.wishlistScreenState = 'open';
        c.UI.closeMenu();
        document.documentElement.classList.add('wishlist-open');
        if (window.GALLERY_SWIPER) {
          window.GALLERY_SWIPER.pauseAutoPlay();
        }
      }
    }),
    closeWishlistScreen: flow(function* () {
      if (c.UI.wishlistScreenState !== 'closed') {
        c.UI.wishlistScreenState = 'closing';
        document.documentElement.classList.remove('wishlist-open');
        if (window.GALLERY_SWIPER) {
          window.GALLERY_SWIPER.resumeAutoPlay();
        }
        c.UI.wishlistScreenState = 'closed';
      }
    }),
    toggleWishlistScreen() {
      if (c.UI.wishlistScreenState === 'open') {
        c.closeWishlistScreen();
      } else {
        c.openWishlistScreen();
      }
    },
  });

  return c;

}

export const WISHLIST = makeWishlistController();

export type WishlistController = ReturnType<typeof makeWishlistController>;