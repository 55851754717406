import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useControllers } from '../../controllers/app.controller';
import { useStore } from '../../utils/mobx.utils';
import ToastCard from './ToastCard';

const ToastStack: React.FC = props => {

  const { UI } = useControllers();
  const s = useStore(() => ({
    get disableToasts() {
      // return COMMON.preferences.disableToasts;
      return false;
    },
    get lastToast() {
      return UI.TOAST.toasts[UI.TOAST.toasts.length - 1];
    }
  }));
  return <Observer children={() => (
    <div className="ToastStack">
      {s.lastToast && !s.disableToasts && <ToastCard
        toast={s.lastToast}
        key={s.lastToast.id}
        canSwipeUpToDismiss
      />}
    </div>
  )} />
}

export default ToastStack;