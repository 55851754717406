import React from 'react';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import joinClassName from '../../utils/className.utils';
import './ErrorBoundary.scss';

interface ErrorBoundaryProps {
  className?: string,
  fallback?: (error?: unknown) => (string | React.ReactElement);
}
interface ErrorBoundaryState {
  error: unknown,
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // reportError(error as Error);
  }

  render() {
    if (!!this.state.error) {
      const { fallback: Fallback } = this.props;
      return <div className={joinClassName('ErrorBoundary', this.props.className)}>
        {
          Fallback ? (
            typeof Fallback === 'string' ? Fallback : Fallback(this.state.error)
          ) : <div className="ErrorBoundaryDefaultError">
              <h1>Oopsie! {APP_CONTENT_CONFIG.siteMetadata.title} has failed to load</h1>
            <p><strong>Error Details:</strong></p>
            <div className="ErrorBoundaryDefaultErrorContent">
              {(this.state.error as any)?.message}
            </div>
            <p>Our engineers have been notified. Please check back later.</p>
          </div>
        }
      </div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;